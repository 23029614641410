<template>
  <div class="nft-details-about-section">
    <p class="about-title">About</p>
    <div class="description-container">
      <div class="nft-description-text">
        {{ nft.description }}
      </div>
      <div class="minted-info">
        <div class="creator-owner-image">
          <div class="img-container pointer" :class="'avatar-img-gray'">
            <img
              alt="CreatorImage"
              :src="avatarImage(nft.creator)"
              class="owner-img"
              @click="goToUserProfile(nft.creator.id)"
            />
          </div>
          <div class="minted-by">
            <span class="by-text">Minted by</span>
            <p class="name pointer" @click="goToUserProfile(nft.creator.id)">
              {{ displayName(nft.creator) }}
            </p>
          </div>
        </div>
        <div class="creator-owner-image">
          <div class="img-container avatar-img-gray">
            <img
              class="royalties-img"
              alt="TheosImage"
              src="@/Common/Icons/TheosLogo.png"
            />
          </div>
          <div class="royalties-section">
            <span class="by-text">Minted in Theos</span>
            <div style="display: flex; gap: 4px">
              <p class="name" v-if="nft.royaltyPercentage">
                {{ nft.royaltyPercentage }}% Royalties
              </p>
              <p class="name" v-else>Without Royalties</p>
              <app-tooltip
                :text="'Percentage that the creator get each<br /> time the NFT is bought.'"
              />
            </div>
          </div>
        </div>
        <div class="creator-owner-image minted-network">
          <div class="img-container avatar-img-gray">
            <img
              v-if="parsedNetworkName === 'Ethereum'"
              class="royalties-img"
              :class="parsedNetworkName.toLowerCase()"
              alt="TheosImage"
              src="@/Common/Icons/eth_network_nft_details.svg"
            />
            <img
              v-if="parsedNetworkName === 'Polygon'"
              class="royalties-img"
              :class="parsedNetworkName.toLowerCase()"
              alt="TheosImage"
              src="@/Common/Icons/polygon_network_nft_details.svg"
            />
          </div>
          <div class="royalties-section">
            <div class="minted-by">
              <span class="by-text">Minted on</span>
              <p class="name">
                {{ parsedNetworkName }}
              </p>
            </div>
          </div>
        </div>
        <div class="creator-owner-image">
          <div class="img-container avatar-img-gray">
            <img
              class="royalties-img"
              alt="TheosImage"
              src="@/Common/Icons/TheosLogo.png"
            />
          </div>
          <div class="royalties-section">
            <span class="by-text">Platform fee</span>
            <div style="display: flex; gap: 4px">
              <p class="name">2%</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nft-tags" v-if="nft.tags">
      <div v-for="(tag, index) in nft.tags" :key="index" class="nft-tag">
        {{ tag }}
      </div>
    </div>
    <div class="nft-info">
      <div>
        <span class="by-text" style="font-size: 14px">Contract address:</span>
        <p class="underlined-text pointer" @click="goToExternal(exploreNftUrl)">
          {{ shortenedContractAddress() }}
          <a target="_blank" :href="exploreNftUrl">
            <i class="link-icon" />
          </a>
        </p>
      </div>
      <div>
        <span class="by-text" style="font-size: 14px">Token ID: </span>
        <p class="text">
          {{ shortenedContractId() }}
        </p>
        <div
          class="copy-icon-box pointer"
          @mouseleave="hideCopyTooltip"
          @click="copyTokenId"
        >
          <i class="copy-icon"></i>
          <div v-if="copyTooltip" class="tooltip-copy">Copied!</div>
        </div>
      </div>
      <div class="minted-network">
        <span class="by-text" style="font-size: 14px">Minted on: </span>
        <p class="text">
          {{ parsedNetworkName }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { ref, computed } from "vue";
import AppTooltip from "@/elements/AppTooltip";
import useNftDetails from "@/composables/useNftDetails";
export default {
  name: "About",
  components: {
    AppTooltip,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
    registeredUser: {
      type: Object,
      default: () => {},
    },
  },
  setup: function (props) {
    const store = useStore();
    let copyTooltip = ref(false);
    const {
      copy,
      avatarImage,
      resetCopyText,
      copyAddressText,
      goToUserProfile,
      contractAddress,
      shortenedContractId,
      shortenedContractAddress,
    } = useNftDetails(store);

    const displayName = (user) => {
      if (user.displayName) return user.displayName;
      if (!user.publicAddress) return "";
      return `${user.publicAddress?.slice(0, 6)}...${user.publicAddress.slice(
        -4
      )}`;
    };
    const hideCopyTooltip = () => {
      copyTooltip.value = false;
    };
    const copyTokenId = () => {
      copyTooltip.value = true;
      navigator.clipboard.writeText(props.nft.contractId);
    };

    const parsedNetworkName = computed(() => {
      return ["mumbai", "polygon"].includes(props.nft?.network)
        ? "Polygon"
        : "Ethereum";
    });
    const exploreNftUrl = computed(() => {
      if (props.nft.network === "mumbai")
        return `https://mumbai.polygonscan.com/address/${contractAddress.value}`;
      if (props.nft.network === "polygon")
        return `https://polygonscan.com/address/${contractAddress.value}`;
      if (props.nft.network === "goerli")
        return `https://goerli.etherscan.io/address/${contractAddress.value}`;
      if (props.nft.network === "ethereum")
        return `https://etherscan.io//address/${contractAddress.value}`;
      return null;
    });
    const goToExternal = (url) => window.open(url, "_blank");

    return {
      copy,
      copyTooltip,
      copyTokenId,
      avatarImage,
      displayName,
      goToExternal,
      resetCopyText,
      copyAddressText,
      contractAddress,
      exploreNftUrl,
      hideCopyTooltip,
      goToUserProfile,
      parsedNetworkName,
      shortenedContractId,
      shortenedContractAddress,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftDetailsAbout.scss" />
