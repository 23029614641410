<template>
  <div>
    <div class="back-button">
      <img alt="Back" @click="goBack()" src="@/Common/Icons/BackButton.svg" />
    </div>
    <div class="collection-cover-image">
      <img :src="collectionCover" alt="Cover" />
    </div>
    <div class="collection-avatar">
      <img :src="collectionAvatar" alt="Avatar" />
    </div>
    <div class="info-container">
      <app-button
        v-if="isWhitelisted"
        buttonClass="edit-button"
        iconClass="edit-icon"
        text="Edit Collection"
        @click="goToEdit"
      />
      <h1 class="title">{{ collection.name || collection.address }}</h1>
      <div class="creator">
        <p>by</p>
        <span>{{ collection.creator }}</span>
      </div>
      <div class="collection-basic-data">
        <div class="creator">
          <p>Original items</p>
          <span>{{ collection.originalItemsNumber || 0 }}</span>
        </div>
        <div class="creator">
          <p>Loaded items</p>
          <span>{{ collection.loaded || 0 }}</span>
        </div>
        <div class="creator">
          <p>Created</p>
          <span>{{ parseDateToMonthAndYear(collection.createdAt) }}</span>
        </div>
        <div class="creator">
          <p>Chain</p>
          <span>{{ parseNetworkNameFromChain(collection.network) }}</span>
        </div>
      </div>
      <div class="description">
        {{ collection.description }}
      </div>
      <div class="collection-statistics">
        <div>
          <p>{{ collection.listed }}</p>
          <span>listed</span>
        </div>
        <div>
          <p>{{ collection.pooled }}</p>
          <span>pooled</span>
        </div>
        <div>
          <p>{{ collection.owners }}</p>
          <span>owners</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { ethereumService } from "@/main";
import { onMounted, computed } from "vue";
import AppButton from "@/elements/AppButton";
import useRouter from "@/composables/useRouter";
import useUtilities from "@/composables/useUtilities";
import useUserProfile from "@/composables/useUserProfile";
import useNetworkData from "@/composables/useNetworkData";

export default {
  name: "InfoSection",
  components: { AppButton },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const { parseDateToMonthAndYear } = useUtilities();
    const { parseNetworkNameFromChain } = useNetworkData(ethereumService);
    const { goTo } = useRouter();
    const collectionId = route.params.id;
    const { isWhitelisted } = useUserProfile(store, router);
    onMounted(async () => {
      await store.dispatch("singleCollection/fetchCollectionById", {
        id: collectionId,
      });
    });
    const goBack = () =>
      goTo({
        name: "Collections",
      });
    const goToEdit = () =>
      goTo({
        name: "UpdateCollection",
        params: collectionId,
      });
    const collection = computed(() =>
      store.getters["singleCollection/getByKey"]("singleCollection")
    );
    const collectionAvatar = computed(() =>
      collection.value.profileImage?.thumbnail
        ? collection.value.profileImage?.thumbnail
        : require("@/Common/Icons/Medusa_avatar.png")
    );
    const collectionCover = computed(() =>
      collection.value.coverImage?.path
        ? collection.value.coverImage?.path
        : require("@/Common/Icons/collection-cover page.png")
    );
    return {
      goBack,
      goToEdit,
      collection,
      isWhitelisted,
      collectionCover,
      collectionAvatar,
      parseDateToMonthAndYear,
      parseNetworkNameFromChain,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/viewCollection.scss" />
