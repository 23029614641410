import { createStore } from "vuex";
import VuexPersistence from "vuex-persist";

import auth from "./modules/auth";
import nfts from "./modules/nfts";
import pool from "./modules/pool";
import pools from "./modules/pools";
import users from "./modules/users";
import bid from "@/store/modules/bid";
import deposit from "./modules/deposit";
import minting from "./modules/minting";
import listNft from "./modules/listNft";
import giftNft from "./modules/giftNft";
import contracts from "./modules/contracts";
import buyNft from "@/store/modules/buyNft";
import tagsValues from "./modules/tagsValues";
import chyronData from "./modules/chyronData";
import checkFunds from "./modules/checkFunds";
import userProfile from "./modules/userProfile";
import withdraw from "@/store/modules/withdraw";
import auctions from "@/store/modules/auctions";
import socialCauses from "./modules/socialCauses";
import registration from "./modules/registration";
import buyListedNft from "./modules/buyListedNft";
import profilePanels from "./modules/profilePanels";
import nftDetails from "@/store/modules/nftDetails";
import marketplace from "@/store/modules/marketplace";
import collections from "@/store/modules/collections";
import poolsGallery from "@/store/modules/poolsGallery";
import depositReview from "@/store/modules/depositReview";
import exchangeTokens from "@/store/modules/exchangeTokens";
import singleCollection from "@/store/modules/singleCollection";

const vuexLocal = new VuexPersistence({
  reducer: (state) => ({
    contracts: state.contracts,
  }),
  storage: window.localStorage,
});

export default createStore({
  modules: {
    bid,
    auth,
    nfts,
    pool,
    users,
    pools,
    buyNft,
    minting,
    deposit,
    listNft,
    giftNft,
    auctions,
    withdraw,
    contracts,
    checkFunds,
    tagsValues,
    nftDetails,
    chyronData,
    userProfile,
    marketplace,
    collections,
    socialCauses,
    registration,
    buyListedNft,
    poolsGallery,
    depositReview,
    profilePanels,
    exchangeTokens,
    singleCollection,
  },
  plugins: [vuexLocal.plugin],
});
