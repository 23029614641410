<template>
  <InfoSection />
  <div class="profile-panel-body">
    <div class="profile-panel-body-wrapper">
      <collection-panel-filters
        :sortOptions="sortOptions()"
        :sortFunction="sortBy"
        :searchFunction="search"
        :isForMarketplace="false"
        :isChecked="isChecked"
        :filterFunction="filter"
        :filterOptions="['network', 'media', 'price']"
        :clearSearchFunction="clearSearch"
        :showTraitrsFilter="showTraitrsFilter()"
      />
      <template v-if="nftList?.all?.data?.length === 0">
        <no-data
          :image="require('@/Common/Icons/spyglass.svg')"
          :title="`Sorry, we don't have any results.`"
        />
      </template>
      <template v-if="nftList?.all?.data?.length">
        <div class="profile-panel-body-content">
          <template v-for="card in nftList?.all.data" :key="card.id">
            <panel-card
              :isForCollection="true"
              :token="card"
              @click="cardClickHandler(card)"
            />
          </template>
        </div>
        <load-more
          currentPanel="all"
          :data="nftList"
          :loadMore="loadMore"
          :loadingMore="false"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onMounted, computed, ref } from "vue";
import useRouter from "@/composables/useRouter";
import NoData from "@/components/Profile/Panels/NoData";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import InfoSection from "@/components/Collections/InfoSection";
import PanelCard from "@/components/Profile/PanelCard/PanelCard";
import useSingleCollection from "@/composables/useSingleCollection";
import CollectionPanelFilters from "@/components/Collections/CollectionPanelFilters.vue";

export default {
  name: "ViewCollection",
  components: {
    InfoSection,
    PanelCard,
    LoadMore,
    NoData,
    CollectionPanelFilters,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { goTo } = useRouter();
    const nftList = computed(
      () => store.getters["singleCollection/getByKey"]("collectionNfts") || []
    );
    const { sortBy, search, sortOptions, clearSearch, isChecked, filter } =
      useSingleCollection(store);

    const collectionId = route.params.id;
    const searchQuery = ref("");
    const loadingMore = computed(() =>
      store.getters["singleCollection/getByKey"]("loadingMore")
    );
    const traitsOptions = computed(() => {
      return store.getters["singleCollection/getByKey"]("traitFilterOptions");
    });
    const showTraitrsFilter = () => {
      if (traitsOptions.value.length > 0) return true;
      return false;
    };
    const loadMore = async () => {
      if (loadingMore.value) return;
      await store.dispatch("singleCollection/commitByKey", {
        loadingMore: true,
      });
      const loadParams = {
        collectionId: collectionId,
      };
      if (searchQuery.value) {
        loadParams["search"] = searchQuery.value;
      }
      await store.dispatch("singleCollection/incrementPage");
      await store.dispatch(
        "singleCollection/fetchNftsForCollection",
        loadParams
      );
      await store.dispatch("singleCollection/commitByKey", {
        loadingMore: false,
      });
    };

    onMounted(() => {
      store.dispatch("singleCollection/resetState");
      store.dispatch("singleCollection/fetchNftsForCollection", {
        collectionId: collectionId,
      });
    });

    const cardClickHandler = (card) =>
      goTo({
        name: "NftDetails",
        params: { id: card.id },
      });

    return {
      nftList,
      sortBy,
      search,
      filter,
      isChecked,
      loadMore,
      clearSearch,
      sortOptions,
      loadingMore,
      cardClickHandler,
      showTraitrsFilter,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/viewCollection.scss"></style>
