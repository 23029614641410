import { eventBus } from "@/main";
import { ref, computed } from "vue";
import { ethereumService } from "@/main";
import useStoreFunctions from "@/composables/useStoreFunctions";

const currentPanel = ref("all");
const MILLISECONDS_MINIMUM_CARD_LOADING = 0;

const useSingleCollection = (store, storeFunctions = useStoreFunctions) => {
  const {
    computeStore: singleCollectionGet,
    dispatchStore: singleCollectionDispatch,
    commitByKey: singleCollectionCommitByKey,
  } = storeFunctions("singleCollection", store);
  const data = singleCollectionGet("collectionNfts");
  // const traitFilterOptions = singleCollectionGet("traitFilterOptions");
  const loadingPanels = singleCollectionGet("loadingPanels");
  const loadingCollections = singleCollectionGet("loadingCollections");
  const loadingMore = singleCollectionGet("loadingMore");

  const searchQuery = singleCollectionGet("searchString");

  const initializePanels = async () => {
    await singleCollectionDispatch("resetState");
    await singleCollectionCommitByKey({ loadingPanels: true });
    await singleCollectionDispatch("fetchNftsForCollection");
    await singleCollectionCommitByKey({ loadingPanels: false });
    await singleCollectionCommitByKey({ loadingMore: false });
  };

  const search = async (searchStringInput) => {
    if (loadingCollections.value) return;
    await singleCollectionDispatch("resetPage");
    await singleCollectionCommitByKey({ loadingCollections: true });
    await singleCollectionCommitByKey({ searchString: searchStringInput });
    await singleCollectionDispatch("resetDataArray", currentPanel.value);
    const searchParams = {
      search: searchStringInput,
    };
    await singleCollectionDispatch("fetchNftsForCollection", searchParams);
    await singleCollectionCommitByKey({ loadingCollections: false });
    if (searchStringInput === "") {
      eventBus.emit("changeCollectionsSearchFlag", false);
    }
  };

  const clearSearch = async () => {
    await singleCollectionDispatch("resetDataArray", currentPanel.value);
    await singleCollectionDispatch("resetPage");
    await singleCollectionDispatch("clearSearch");
    await singleCollectionCommitByKey({ loadingCollections: true });
    await singleCollectionDispatch("fetchNftsForCollection");
    await singleCollectionCommitByKey({ loadingCollections: false });
  };

  const sortBy = async (sort) => {
    await singleCollectionDispatch("resetPage");
    await singleCollectionDispatch("resetDataArray", currentPanel.value);
    await singleCollectionDispatch("setSort", sort);
    await singleCollectionCommitByKey({ loadingCollections: true });
    await singleCollectionDispatch("fetchNftsForCollection");
    await singleCollectionCommitByKey({ loadingCollections: false });
  };

  const elementExist = async (prevQuery, filter) => {
    let indexOf = prevQuery?.indexOf(filter);
    if (indexOf >= 0) {
      return prevQuery.splice(indexOf, 1);
    }
    if (indexOf === -1) {
      return prevQuery.push(filter);
    }
    return false;
  };
  const elementExistTrait = async (prevQuery, filter) => {
    const isFound = prevQuery.find(
      (prevFilter) =>
        prevFilter.traitType === filter.traitType &&
        prevFilter.traitValue === filter.traitValue
    );

    if (isFound && isFound.traitType === filter.traitType) {
      if (isFound.traitValue && filter.traitValue) {
        return prevQuery.pop();
      }
      prevQuery.pop();
      prevQuery.push(filter);
    }
    if (!isFound) {
      if (prevQuery.traitType !== filter.traitType) prevQuery.pop();
      return prevQuery.push(filter);
    }
    return false;
  };
  const query = computed(() =>
    store.getters["singleCollection/getByKey"]("queries")
  );

  const filter = async (filter) => {
    const prevQuery = { ...query.value };
    eventBus.emit("changeMarketplaceFilterAndSortFlag", true);
    await singleCollectionDispatch("resetPage");
    await singleCollectionDispatch("resetDataArray", currentPanel.value);

    if (filter.type === "price") {
      await elementExist(prevQuery.priceRange, filter.priceRange);
      await singleCollectionDispatch("setFilter", {
        priceRange: prevQuery.priceRange,
      });
    }

    if (filter.type === "media") {
      await elementExist(prevQuery.media, filter.value);
      await singleCollectionDispatch("setFilter", {
        media: prevQuery.media,
      });
    }

    if (filter.type === "network") {
      const networkFilter = ethereumService.availableNetworks[filter.value];
      await elementExist(prevQuery.networks, networkFilter);
      await singleCollectionDispatch("setFilter", {
        networks: prevQuery.networks,
      });
    }
    if (filter.type === "traits") {
      await elementExistTrait(prevQuery.attributes, filter.value);
      await singleCollectionDispatch("setFilter", {
        attributes: prevQuery.attributes,
      });
    }
    if (filter.type === "clear") {
      await singleCollectionDispatch("setFilter", {
        media: [],
        priceRange: [],
        networks: [],
      });
    }
    await singleCollectionCommitByKey({ loadingCollections: true });
    await singleCollectionDispatch("fetchNftsForCollection");
    await singleCollectionCommitByKey({ loadingCollections: false });

    if (prevQuery.media.length === 0 && prevQuery.priceRange.length === 0) {
      eventBus.emit("changeMarketplaceFilterAndSortFlag", false);
    }
  };

  const isChecked = (type, value) => {
    if (type === "price" && query.value.priceRange.includes(value)) {
      return true;
    }

    if (type === "traits") {
      return query.value.attributes.find(
        (prevFilter) =>
          prevFilter.traitType === value.traitType &&
          prevFilter.traitValue === value.traitValue
      );
    }

    if (type === "network") {
      const networkFilter = ethereumService.availableNetworks[value];
      return query.value.networks.includes(networkFilter);
    }

    return type === "media" && query.value.media.includes(value);
  };

  const activeSort = computed(
    () => `${query.value.orderBy}_${query.value.orderDirection.toLowerCase()}`
  );
  const sortOptions = () => {
    return [
      {
        title: "Newest NFT",
        value: {
          orderDirection: "DESC",
          orderBy: "id",
        },
      },
      {
        title: "Oldest NFT",
        value: {
          orderDirection: "ASC",
          orderBy: "id",
        },
      },
      {
        title: "Highest Price",
        value: {
          orderDirection: "DESC",
          orderBy: "priceUsd",
        },
      },
      {
        title: "Lowest Price",
        value: {
          orderDirection: "ASC",
          orderBy: "priceUsd",
        },
      },
    ];
  };
  const loadMore = async () => {
    if (loadingMore.value) return;
    await singleCollectionCommitByKey({ loadingMore: true });
    const loadParams = {};
    if (searchQuery.value) {
      loadParams["search"] = searchQuery.value;
    }
    setTimeout(async () => {
      await singleCollectionDispatch("incrementPage");
      await singleCollectionDispatch("fetchNftsForCollection", loadParams);
      await singleCollectionCommitByKey({ loadingMore: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  return {
    data,
    filter,
    sortBy,
    search,
    loadMore,
    isChecked,
    activeSort,
    sortOptions,
    clearSearch,
    loadingMore,
    currentPanel,
    loadingPanels,
    initializePanels,
    loadingCollections,
  };
};

export default useSingleCollection;
