<template>
  <div class="avatar-img-wrapper" :style="isBig ? 'min-height: 80%' : ''">
    <img :src="coverImage(collection)" alt="Thumbnail" class="app-card-image" />
  </div>
</template>

<script>
import { ref } from "vue";
import imageTransformer from "@/plugins/imageTransformer";
export default {
  props: {
    external: {
      type: Boolean,
      default: false,
    },
    collection: {
      type: Object,
      default: () => {},
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const loadNFT = () => {
      isLoading.value = true;
    };

    const isLoading = ref(false);
    const showPopup = ref(false);

    const coverImage = (collection) => {
      return collection?.coverImage && collection?.coverImage?.filename
        ? imageTransformer.transform(
            collection.coverImage.filename,
            "collection.list"
          )
        : require("@/Common/Icons/collection-card cover.jpg");
    };

    return {
      loadNFT,
      showPopup,
      isLoading,
      coverImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />
