import Home from "@/views/Home";
import Pools from "@/views/Pools";
import MintNft from "@/views/MintNft";
import Error404 from "@/views/Error404.vue";
import ViewPool from "@/views/ViewPool.vue";
import Marketplace from "@/views/Marketplace";
import Collections from "@/views/Collections";
import DepositNft from "@/views/DepositNft.vue";
import PoolsGallery from "@/views/PoolsGallery";
import EditProfile from "@/views/EditProfile.vue";
import ViewProfilePublic from "@/views/ViewProfilePublic";
import { createRouter, createWebHistory } from "vue-router";
import ViewProfilePrivate from "@/views/ViewProfilePrivate";
import ViewCollection from "@/views/Collections/ViewCollection";
import UpdateCollection from "@/views/Collections/UpdateCollection";

import store from "../store";
import api from "../services/api";
import { ethereumService } from "../main";
import NftDetails from "@/views/NftDetails";

import { trackRouter } from "vue-gtag-next";
import useAuth from "../composables/useAuth";
import useUserProfile from "../composables/useUserProfile";

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: async (to, from, next) => {
      try {
        const response = await api.fetchMe();
        if (response.status === 200) return next();
      } catch (err) {
        const { disconnectInRouter } = useAuth(store, ethereumService);
        await disconnectInRouter();
        next();
      }
    },
  },
  {
    path: "/minting",
    name: "Minting",
    component: MintNft,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     const response = await api.fetchMe();
    //     if (response.status === 200) return next();
    //   } catch (err) {
    //     const { disconnectInRouter } = useAuth(store, ethereumService);
    //     await disconnectInRouter();
    //     next("/");
    //   }
    // },
  },
  {
    path: "/profile/me",
    name: "ViewProfilePrivate",
    component: ViewProfilePrivate,
    beforeEnter: async (to, from, next) => {
      try {
        const response = await api.fetchMe();
        if (response.status === 200) return next();
      } catch (err) {
        const { disconnectInRouter } = useAuth(store, ethereumService);
        await disconnectInRouter();
        next("/");
      }
    },
  },
  {
    path: "/profile/:id",
    name: "ViewProfilePublic",
    component: ViewProfilePublic,
  },
  {
    path: "/edit-profile",
    name: "EditProfile",
    component: EditProfile,
    beforeEnter: async (to, from, next) => {
      try {
        const response = await api.fetchMe();
        if (response.status === 200) return next();
      } catch (err) {
        const { disconnectInRouter } = useAuth(store, ethereumService);
        await disconnectInRouter();
        next("/");
      }
    },
  },
  {
    path: "/pools/:id",
    name: "ViewPool",
    component: ViewPool,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     const response = await api.fetchMe();
    //     if (response.status === 200) return next();
    //   } catch (err) {
    //     next("/");
    //   }
    // },
  },
  {
    path: "/pools-gallery",
    name: "PoolsGallery",
    component: PoolsGallery,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     const response = await api.fetchMe();
    //     if (response.status === 200) return next();
    //   } catch (err) {
    //     next("/");
    //   }
    // },
  },
  {
    path: "/nft-details/:id",
    name: "NftDetails",
    component: NftDetails,
    beforeEnter: async (to, from, next) => {
      try {
        await store.dispatch("nftDetails/resetState");
        return next();
      } catch (err) {
        next("/");
      }
    },
  },
  {
    path: "/create-pool",
    name: "Pools",
    component: Pools,
    beforeEnter: async (to, from, next) => {
      try {
        const response = await api.fetchMe();
        if (response.status === 200) {
          const data = {
            address: response.data.data.publicAddress,
          };
          const isWhitelisted = await api.fetchIsWhitelisted(data);
          if (!isWhitelisted.data) next("/");
          return next();
        }
      } catch (err) {
        next("/");
      }
    },
  },
  {
    path: "/deposit",
    name: "DepositNft",
    component: DepositNft,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     const response = await api.fetchMe();
    //     if (response.status === 200) return next();
    //   } catch (err) {
    //     const { loginOrSignUp } = useAuth(store, ethereumService);
    //     await loginOrSignUp(false, true);
    //     try {
    //       const response = await api.fetchMe();
    //       if (response.status === 200) return next();
    //     } catch(error) {
    //       next("/")
    //     }
    //   }
    // },
  },
  {
    path: "/marketplace",
    name: "Marketplace",
    component: Marketplace,
    // beforeEnter: async (to, from, next) => {
    //   try {
    //     const response = await api.fetchMe();
    //     if (response.status === 200) return next();
    //   } catch (err) {
    //     next("/");
    //   }
    // },
  },
  {
    path: "/collections",
    name: "Collections",
    component: Collections,
  },
  {
    path: "/collections/update/:id",
    name: "UpdateCollection",
    component: UpdateCollection,
    beforeEnter: async (to, from, next) => {
      try {
        const { walletAddress } = useUserProfile(store, router);
        const response = await api.fetchIsWhitelisted({
          address: walletAddress.value,
        });
        if (response.data === true) return next();
        next("/");
      } catch (err) {
        next("/");
      }
    },
  },
  {
    path: "/collections/:id",
    name: "ViewCollection",
    component: ViewCollection,
  },
  {
    path: "/404",
    name: "Error404",
    component: Error404,
  },
  { path: "/404", name: "Error404", component: Error404 },
  {
    path: "/:pathMatch(.*)*",
    name: "Error404",
    component: Error404,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});
trackRouter(router, {
  useScreenview: true,
});

export default router;
