import useToast from "./useToast";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import useGoogleTag from "@/composables/useGoogleTag";
import useRegistrationModal from "@/composables/useRegistrationModal";
import { startWebSocket } from "@/services/webSocket";

const walletConnectionRequired = ref(false);

const useAuth = (store, ethereumService, router = useRouter()) => {
  const { showToast } = useToast();

  const toggleWalletConnectionRequired = () =>
    (walletConnectionRequired.value = !walletConnectionRequired.value);

  const { registerEvent, registerException } = useGoogleTag();

  const disconnect = async (disconnectProvider = false) => {
    await store.dispatch("contracts/resetState");
    await store.dispatch("auth/disconnect");
    if (disconnectProvider) {
      await ethereumService.disconnectProvider();
    }
    window.localStorage.removeItem("me");
    await store.dispatch("contracts/fetchCoinPrice", {});
    // await unsubscribeFromPushNotifications();
    return await router.push({ name: "Home" });
  };

  const disconnectInRouter = async () => {
    await store.dispatch("contracts/resetState");
    await store.dispatch("auth/disconnect");
    await ethereumService.disconnectProvider();
  };

  const handleAccountChange = async () => {
    await store.dispatch("contracts/fetchIsValidNetwork");
    await showToast(
      `Account change`,
      `Please, sign the transaction on Metamask to continue.`,
      "Success"
    );
    await disconnect();
    await loginOrSignUp();
  };

  const handleChainChange = async (chain) => {
    await store.dispatch("contracts/fetchIsValidNetwork");
    if (chain === "Selected network is not supported.") {
      await showToast("Network not supported", chain, "Error");
      await store.dispatch("auth/commitByKey", {
        isBeginConnectionModalOpen: true,
      });
      return await disconnect(true);
    }
    await showToast("Network changed", chain, "Success");
    let publicAddress = await store.dispatch("contracts/fetchAddress");
    await store.dispatch("contracts/fetchNetwork");
    await store.dispatch("contracts/fetchBalance", publicAddress);
  };

  const loginOrSignUp = async (
    isAccountChange = false,
    isConnectButton = false
  ) => {
    registerEvent("Connect to a wallet button");
    console.log(isAccountChange);
    const metamaskNotInstalled = await isMetamaskNotInstalled();
    if (metamaskNotInstalled) {
      store.dispatch("auth/commitByKey", {
        installMetamaskFlag: true,
      });
      return;
    }

    const metamaskConnected = await ethereumService.isMetaMaskConnected();
    if (!metamaskConnected && isConnectButton) {
      await store.dispatch("auth/commitByKey", {
        isBeginConnectionModalOpen: true,
      });
      return;
    }
    await ethereumService.connectToProvider();

    const isValidNetwork = await ethereumService.isValidNetwork();
    await store.dispatch("contracts/fetchIsValidNetwork");
    if (!isValidNetwork) {
      registerException("Network not supported", false);
      await showToast(
        "Network not supported",
        "Selected network is not supported.",
        "Error"
      );
      return await disconnect(true);
    }

    await store.dispatch("users/updatePendingTransaction", true);
    await store.dispatch("contracts/fetchNetwork");
    let publicAddress = await store.dispatch("contracts/fetchAddress");
    await store.dispatch("contracts/fetchBalance", publicAddress);
    let nonce = await store.dispatch("auth/fetchNonce", publicAddress);
    if (!nonce) {
      const signupResponse = await store.dispatch("auth/signUp", publicAddress);
      nonce = signupResponse.data.nonce;
    }
    await store.dispatch("auth/commitByKey", {
      isBeginConnectionModalOpen: false,
    });
    const signature = await store.dispatch("contracts/signNonce", nonce);
    if (signature === "rejected") {
      registerException("Metamask signature rejected", false);
      await showToast(
        "MetaMask error",
        "Please, sign the message to access the advanced features of the application.",
        "Error"
      );
      await store.dispatch("users/updatePendingTransaction", false);
      return disconnect();
    }
    await store.dispatch("auth/verifyNonce", {
      signature,
      publicAddress,
    });
    await store.dispatch("userProfile/fetchMe");

    const { toggleRegistrationFlag } = useRegistrationModal(store);
    await store.dispatch("users/updatePendingTransaction", false);
    await store.dispatch("userProfile/fetchMe");
    const registered = await isUserRegistered();
    if ((await ethereumService.isProviderConnected()) && !registered) {
      toggleRegistrationFlag();
    }
    await store.dispatch("contracts/fetchNetworkName");

    await store.dispatch("auth/commitByKey", {
      isLoggedIn: true,
    });
    await store.dispatch("userProfile/isWhitelisted", {
      address: publicAddress,
    });
    // await subscribeToPushNotifications();
    startWebSocket();
    registerEvent("Successful login");
  };

  const openRegistrationModal = async () => {
    store.dispatch("registration/setByKey", {
      registrationFromProfileFlag: true,
    });
    const address = computed(() => store.getters["contracts/getAddress"]);
    const { toggleRegistrationFlag } = useRegistrationModal(store);
    if (address.value) {
      const registered = await isUserRegistered();
      if (!registered) {
        toggleRegistrationFlag();
      }
    }
  };

  const isUserRegistered = async () => {
    await store.dispatch("auth/fetchMe");
    const me = store.getters["auth/getRegisteredUser"];
    return Boolean(me.displayName);
  };

  const registeredUser = computed(
    () => store.getters["auth/getRegisteredUser"]
  );

  const showBadge = (type) => {
    const me = store.getters["auth/getRegisteredUser"];
    //if badges is empty or it does not includes type
    return me.badges === null || !me.badges.includes(type);
  };

  const setBadge = async (type) => {
    await store.dispatch("auth/fetchMe");
    const userData = {
      email: "",
      displayName: "",
      badges: [],
    };
    const me = store.getters["auth/getRegisteredUser"];
    if (
      (me.email !== null && me.displayName !== null && me.badges === null) ||
      (me.badges !== null && !me.badges.includes(type))
    ) {
      userData.displayName = me.displayName;
      userData.email = me.email;
      userData.badges.push(type);
      store.dispatch("auth/updateUser", {
        updateUser: userData,
        id: me.id,
      });
    }
  };

  const isLoggedIn = computed(() =>
    store.getters["auth/getByKey"]("isLoggedIn")
  );

  const isMetamaskNotInstalled = () => {
    return ethereumService.isMetamaskNotInstalled();
  };

  const installMetamaskFlag = computed(() => {
    return store.getters["auth/getByKey"]("installMetamaskFlag");
  });

  const isBeginConnectionModalOpen = computed(() =>
    store.getters["auth/getByKey"]("isBeginConnectionModalOpen")
  );

  return {
    setBadge,
    showBadge,
    isLoggedIn,
    loginOrSignUp,
    registeredUser,
    handleChainChange,
    disconnectInRouter,
    installMetamaskFlag,
    handleAccountChange,
    openRegistrationModal,
    isMetamaskNotInstalled,
    walletConnectionRequired,
    isBeginConnectionModalOpen,
    toggleWalletConnectionRequired,
  };
};

export default useAuth;
