<template>
  <div>
    <label :for="id" :class="labelClass">{{ labelText }}</label>
    <div class="tag-input" :class="tagInputWrap">
      <input
        hidden
        :id="id"
        :name="name"
        v-bind="$attrs"
        v-model="inputValue"
        :disabled="disabled"
        :class="inputTagClass"
        :style="inputTagStyle"
        class="tag-input-text"
        :placeholder="placeholder"
      />
      <Multiselect
        v-model="tagValue"
        mode="tags"
        class="tag-input-text"
        :style="inputTagStyle"
        :options="tagsList"
        :searchable="true"
        :close-on-select="false"
        :placeholder="placeholder"
      >
        <template #placeholder>
          <div class="multiselect-placeholder">
            {{ placeholder }}
          </div>
        </template>
      </Multiselect>
    </div>
    <div class="suggestions-box">
      <p v-if="tagSuggestionText">{{ tagSuggestionText }}</p>
    </div>
    <p :class="messageClass" v-show="errorMessage || !meta.valid">
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref, watch } from "vue";
import { useField } from "vee-validate";
import Multiselect from "@vueform/multiselect";
export default {
  name: "AppInputTag",
  props: {
    id: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputTagClass: {
      type: String,
      default: "",
    },
    messageClass: {
      type: String,
      default: "",
    },
    tagInputWrap: {
      type: String,
      default: "",
    },
    inputTagStyle: {
      type: Object,
      default: () => {},
    },
    suggestions: {
      type: Array,
      default: () => [],
    },
    tagSuggestionText: {
      type: String,
      default: "",
    },
  },
  components: {
    Multiselect,
  },
  setup(props, ctx) {
    const store = useStore();
    const tagsList = [
      { value: "game", label: "Game" },
      { value: "art", label: "Art" },
      { value: "music", label: "Music" },
      { value: "ip", label: "IP" },
      { value: "photography", label: "Photography" },
      { value: "collectibles", label: "Collectibles" },
      { value: "sports", label: "Sports" },
      { value: "trading cards", label: "Trading cards" },
      { value: "utility", label: "Utility" },
      { value: "generative art", label: "Generative art" },
      { value: "virtual worlds", label: "Virtual worlds" },
      { value: "other", label: "Other" },
    ];
    const tags = computed(() => store.getters["tagsValues/getByKey"]("tags"));

    const {
      meta,
      errorMessage,
      value: inputValue,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    const tagValue = ref(props.modelValue);

    watch(tagValue, (newValue) => {
      ctx.emit("update:modelValue", newValue);
      store.dispatch("tagsValues/setTags", newValue);
    });

    return {
      tags,
      meta,
      tagValue,
      tagsList,
      inputValue,
      errorMessage,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/appInputTag.scss" />
