<template>
  <div
    class="owner-img"
    v-if="collection.profileImage"
    @click.stop="goToPublicProfile(collection?.profileImage.id)"
    :style="{ width: `${cardSize * 1.5}px`, height: `${cardSize * 1.5}px` }"
    :class="
      collection?.profileImage.id === registeredUser?.id
        ? 'avatar-img-border-holo'
        : ''
    "
  >
    <img :src="ownerAvatarImage(collection)" alt="Owner" />
  </div>
  <div
    v-else
    class="owner-img"
    :style="{ width: `${cardSize * 1.5}px`, height: `${cardSize * 1.5}px` }"
  >
    <img alt="Owner" :src="require('@/Common/Icons/collection-profile.jpg')" />
  </div>
</template>

<script>
import { useStore } from "vuex";
import useAuth from "@/composables/useAuth";
import useRouter from "@/composables/useRouter";
import imageTransformer from "@/plugins/imageTransformer";
export default {
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    cardSize: {
      type: Number,
      default: 120,
    },
  },
  setup() {
    const store = useStore();
    const { goTo } = useRouter();
    const { registeredUser } = useAuth(store);

    const creatorAvatarImage = (creator) => {
      if (creator.avatarFileName)
        return imageTransformer.transform(
          creator.avatarFileName.filename,
          "user.avatar"
        );
      if (creator.avatarImageUrl) return creator.avatarImageUrl;
      if (creator.profileImage)
        return imageTransformer.transform(
          creator.profileImage.filename,
          "user.avatar"
        );
      return require("@/Common/Icons/Medusa_avatar.png");
    };

    const ownerAvatarImage = (collection) => {
      if (collection?.profileImage.filename)
        return imageTransformer.transform(
          collection?.profileImage?.filename,
          "user.avatar"
        );
      if (collection.profileImage) return collection.profileImage.path;
      return require("@/Common/Icons/Medusa_avatar_bg.png");
    };

    const goToPublicProfile = (id) =>
      goTo({
        params: { id },
        name: "ViewProfilePublic",
      });

    return {
      registeredUser,
      ownerAvatarImage,
      goToPublicProfile,
      creatorAvatarImage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />
