<template>
  <div class="app-modal-overlay" v-if="listFlag">
    <div class="overlay-blur" :style="backgroundImage" />
    <div class="app-modal-wrapper">
      <div
        class="app-modal-leave-modal"
        @click="closeModal()"
        @keypress.enter.prevent
      >
        <img src="@/Common/Icons/XmarkModal.png" alt="Logo" />
      </div>
      <div class="auction-modal-left-panel">
        <div class="left-panel-wrapper">
          <div class="body">
            <div class="panel-title">
              <span class="panel-title-number">1</span>
              <span class="panel-title-text">LIST BY</span>
            </div>
            <app-button
              class="app-modal-button-gray-dark-border button-margin"
              :class="
                selectedType === 'fixed-price'
                  ? 'app-modal-button-gray-dark-border-selected'
                  : ''
              "
              @click="changeSelectedType('fixed-price')"
              text="Fixed Price"
            />
            <app-button
              class="app-modal-button-gray-dark-border"
              :class="
                selectedType === 'time-limited'
                  ? 'app-modal-button-gray-dark-border-selected'
                  : ''
              "
              @click.prevent="changeSelectedType('time-limited')"
              text="Timed Auction"
            />
          </div>
          <div class="footer">
            How it works? <span class="dark">Find out more in our </span>
            <a
              href="https://theos.fi/faq"
              target="_blank"
              style="text-decoration: none"
            >
              <span class="link">documentation page</span>
            </a>
          </div>
        </div>
      </div>
      <div class="auction-modal-center-panel">
        <welcome-panel v-if="!selectedType" />
        <fixed-price-panel v-if="selectedType === 'fixed-price'" />
        <timed-auction-panel v-if="selectedType === 'time-limited'" />
      </div>
      <div class="auction-modal-right-panel">
        <div class="right-panel-wrapper">
          <div class="body body-top">
            <div class="panel-title">
              <span class="panel-title-text">PREVIEW</span>
            </div>
            <img
              :src="coverImage(nft.coverImage)"
              alt="nft"
              class="auction-modal-image"
            />
            <p class="nft-name">{{ nft.name }}</p>
            <p class="nft-network">
              <img
                :src="
                  require(nft.network === 'mumbai'
                    ? '@/Common/Icons/polygon-logo.svg'
                    : '@/Common/Icons/eth.svg')
                "
                alt="LOGO"
              />
              Minted in {{ parsedNetworkName }}
            </p>

            <div class="auction-details" v-if="selectedType === 'time-limited'">
              <p class="sold-for">Your auction will end at</p>
              <p class="sold-for-price">
                {{ endTimeFormated }}
              </p>
            </div>
            <div
              class="auction-details"
              v-if="selectedType === 'time-limited' && timedAuction.startPrice"
            >
              <p class="sold-for">The least you will receive</p>
              <p class="sold-for-price">
                {{ timedAuction.startPrice }} {{ selectedToken?.token }}
                <span class="sold-for"> ≈ ${{ startPriceUsd }} </span>
              </p>
            </div>
            <div
              class="auction-details"
              v-if="
                selectedType === 'time-limited' && timedAuction.reservedPrice
              "
            >
              <p class="sold-for">Automatically sell at</p>
              <p class="sold-for-price">
                {{ timedAuction.reservedPrice }} {{ selectedToken?.token }}
                <span class="sold-for"> ≈ ${{ reservedPriceUsd }} </span>
              </p>
            </div>

            <div
              class="auction-details"
              v-if="selectedType === 'fixed-price' && listingPrice"
            >
              <p class="sold-for">Your NFT will be sold for</p>
              <p class="sold-for-price">
                {{ listingPrice }} {{ parsedSelectedToken?.token }}
                <span class="sold-for"> ≈ ${{ calculatedNftPriceUsd }} </span>
              </p>
            </div>
          </div>
          <div class="body body-bottom">
            <p class="theos-fee">THEOS fee 2%</p>

            <div class="info">
              <img
                class="info-icon info-img"
                src="@/Common/Icons/InfoRoyaltiesIcon.png"
              />
              <div class="info-hover">
                Listing an item is free. If sold, <br />
                the following fees will be deducted.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <completing-modal
    :text="'Sign transaction in your wallet to continue'"
    :title="spinnerModalText"
    v-if="showLisitingSpinner"
  />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import AppButton from "@/elements/AppButton";
import WelcomePanel from "./WelcomePanel.vue";
import useListNft from "@/composables/useListNft";
import useRouter from "../../composables/useRouter";
import FixedPricePanel from "./FixedPricePanel.vue";
import TimedAuctionPanel from "./TimedAuctionPanel.vue";
import CompletingModal from "../ListNft/CompletingModal.vue";
import imageTransformer from "../../plugins/imageTransformer";
import useNetworkData from "../../composables/useNetworkData";
import { ethereumService } from "@/main";
export default {
  name: "AuctionModal",
  components: {
    AppButton,
    WelcomePanel,
    CompletingModal,
    FixedPricePanel,
    TimedAuctionPanel,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const { goTo } = useRouter();

    const { networkLogo } = useNetworkData(ethereumService);

    const closeModal = async () => {
      document.body.classList.remove("stop-scroll");
      toggleListFlag();
      await resetState();
      if (route.path === "/minting") {
        goTo({ name: "ViewProfilePrivate" });
      }
    };

    const endTimeFormated = computed(() => {
      return timedAuctionEndTime.value.toFormat("MM/dd/yyyy HH:mm 'hs'");
    });

    const coverImage = (coverImage) =>
      !!coverImage && !!coverImage.filename
        ? imageTransformer.transform(coverImage.filename, "auction.image")
        : null;

    const backgroundImage = computed(() => {
      return nft?.value?.coverImage?.path !== ""
        ? `background: linear-gradient(rgba(0, 0, 0, 0.6),rgba(0, 0, 0, 0.6)) , url("${imageTransformer.transform(
            nft?.value?.coverImage?.filename,
            "nft.background"
          )}") no-repeat center center/cover`
        : "";
    });

    const spinnerModalText = computed(() => {
      return selectedType.value === "fixed-price"
        ? "LISTING..."
        : "LISTING AUCTION...";
    });

    const parsedNetworkName = computed(() => {
      return ["mumbai", "polygon"].includes(nft?.value.network)
        ? "Polygon"
        : "Ethereum";
    });
    const parseTokenSymbol = (selectedToken) => {
      if (selectedToken) {
        let tokenSymbol = "MATIC";
        if (selectedToken.token === "wETH") tokenSymbol = "ETH";
        selectedToken.token = tokenSymbol;
      }
      return selectedToken;
    };

    const parsedSelectedToken = computed(() =>
      parseTokenSymbol(JSON.parse(JSON.stringify(selectedToken.value)))
    );
    const changeSelectedType = (type) => {
      if (selectedType.value !== type) return setSelectedType(type);
    };
    const {
      nft,
      listFlag,
      resetState,
      listingPrice,
      selectedType,
      timedAuction,
      startPriceUsd,
      selectedToken,
      toggleListFlag,
      setSelectedType,
      reservedPriceUsd,
      timedAuctionEndTime,
      showLisitingSpinner,
      calculatedNftPriceUsd,
    } = useListNft(store);
    return {
      nft,
      listFlag,
      closeModal,
      coverImage,
      networkLogo,
      timedAuction,
      listingPrice,
      selectedType,
      selectedToken,
      startPriceUsd,
      toggleListFlag,
      endTimeFormated,
      setSelectedType,
      backgroundImage,
      reservedPriceUsd,
      spinnerModalText,
      parsedNetworkName,
      parsedSelectedToken,
      changeSelectedType,
      showLisitingSpinner,
      timedAuctionEndTime,
      calculatedNftPriceUsd,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/auctionModal.scss"></style>
