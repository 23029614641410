<template>
  <div :class="wrapperClass">
    <div
      :class="[{ pointer: !external }]"
      class="card-wrapper"
      ref="cardWrapper"
    >
      <div class="card">
        <collection-network-badge
          :collection="collection"
          :cardSize="cardSize"
        />
        <collection-card-image :collection="collection" :external="external" />
        <collection-card-content
          :collection="collection"
          :currentPanel="currentPanel"
          :isForMarketplace="isForMarketplace"
        />
        <collection-card-creator-owner-image
          :collection="collection"
          :cardSize="cardSize"
          :isForMarketplace="isForMarketplace"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { computed } from "vue";
import { onBeforeUnmount, onMounted, ref } from "vue";
import CollectionCardImage from "@/components/Collections/CollectionCardImage";
import CollectionCardContent from "@/components/Collections/CollectionCardContent";
import CollectionNetworkBadge from "@/components/Collections/CollectionNetworkBadge";
import CollectionCardCreatorOwnerImage from "@/components/Collections/CollectionCardCreatorOwnerImage";
export default {
  components: {
    CollectionCardImage,
    CollectionCardContent,
    CollectionNetworkBadge,
    CollectionCardCreatorOwnerImage,
  },
  props: {
    currentPanel: {
      type: String,
      default: "",
    },
    external: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    collection: {
      type: Object,
      default: () => {},
    },
    isBig: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const cardSize = ref(null);
    const iconSize = ref(null);
    const cardWrapper = ref(null);
    const wrapperClass = computed(() =>
      props.isBig ? "card-icon-wrapper-big" : "card-icon-wrapper"
    );

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0].inlineSize
          : entry.contentBoxSize.inlineSize;

        if (props.isBig) contentBoxSize /= 2;

        cardSize.value = Math.floor(contentBoxSize * 0.142);
        iconSize.value = Math.floor(contentBoxSize * 0.157);
      }
    });

    onMounted(() => {
      resizeObserver.observe(cardWrapper.value);
    });

    onBeforeUnmount(() => resizeObserver.unobserve(cardWrapper.value));

    return {
      cardSize,
      iconSize,
      cardWrapper,
      wrapperClass,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/collectionCard.scss" />
