<template>
  <div class="nft-tags-share">
    <div
      class="nft-collection-info"
      v-if="nft?.collectionName && nft.collectionName !== 'Theos NFT mint'"
    >
      {{ proportionNft }}
      <img
        class="collection-icon"
        :src="require('@/Common/Icons/collection-icon.svg')"
      />
      <span class="collection-name">
        {{ nft.collectionName }}
        <span>collection</span>
      </span>
    </div>
    <div class="share-nft-wrapper">
      <div
        class="share-nft-button-wrapper"
        v-if="!isNftPooled() && isOwner"
        @click.prevent="openGiftModal()"
      >
        <div class="share-button-no-text">
          <img src="@/Common/Icons/giftNft.svg" alt="Gift" />
        </div>
      </div>
      <share-to-social-media
        text=""
        :type="type"
        :shareIcon="true"
        popupText="Share NFT"
      />
    </div>
  </div>
</template>

<script>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { ethereumService } from "@/main";
import useGiftNft from "@/composables/useGiftNft";
import useNftDetails from "@/composables/useNftDetails";
import useNetworkData from "@/composables/useNetworkData";
import ShareToSocialMedia from "@/components/Share/ShareToSocialMedia";

export default {
  name: "SecondaryActions",
  components: {
    ShareToSocialMedia,
  },
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  setup: function (props) {
    const store = useStore();
    const nftImageProportion = ref("");
    const { isNftPooled, isListed, isOwner } = useNftDetails(store);
    const { initializeGiftModal, toggleGiftingListedNftFlag } =
      useGiftNft(store);
    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

    const type = computed(() => {
      if (isNftPooled()) return "pooled_nft";
      if (isListed.value) return "listed_nft";
      if (isOwner.value) return "personal_nft";
      return "public_nft";
    });

    const openGiftModal = () => {
      if (isNftOnCurrentNetwork(props.nft)) {
        if (props.nft.status !== "listed") {
          return initializeGiftModal(props.nft.id);
        }
        toggleGiftingListedNftFlag();
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    return {
      type,
      isOwner,
      isNftPooled,
      openGiftModal,
      nftImageProportion,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/secondaryActions.scss"></style>
