<template>
  <input-form-box formTitle="your pool" :inputFormBoxStyle="inputFormBoxStyle">
    <section>
      <div class="tags-royalties-wrap">
        <app-input
          type="text"
          name="name"
          labelText="Name"
          labelClass="label"
          v-model="pool.name"
          class="tags-royalties"
          inputClass="form-box-input"
          :placeholder="'Choose a name for your pool'"
        />
        <app-input-tag
          type="text"
          name="tags"
          labelText="Tags (optional)"
          v-model="tagValue"
          labelClass="label"
          class="tags-royalties-tag"
          tagInputWrap="tag-input-wrap"
          inputTagClass="form-box-input"
          :placeholder="'Add tags that identify your pool'"
        />
      </div>
      <div class="voice-over-wrap">
        <app-textarea
          cols="1"
          rows="4"
          type="text"
          labelClass="label"
          name="description"
          labelText="Description"
          v-model="pool.description"
          componentClass="section-inputs"
          textAreaClass="form-box-textarea"
          :placeholder="'Describe what your pools is about'"
          maxlength="200"
        />
        <span class="description-num-chars"
          >{{ pool.description.length }}/200
        </span>
      </div>
    </section>
    <div class="form-box-buttons-controls">
      <app-button
        text="Back"
        @click="agree(false)"
        buttonClass="app-modal-button"
      />
      <app-button
        text="Next"
        type="submit"
        form="create-pool"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </input-form-box>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import usePool from "@/composables/usePool";
import AppButton from "@/elements/AppButton";
import AppTextarea from "@/elements/AppTextarea";
import AppInputTag from "@/elements/AppInputTag";
import InputFormBox from "@/elements/InputFormBox";
export default {
  components: {
    AppInput,
    AppButton,
    AppTextarea,
    AppInputTag,
    InputFormBox,
  },
  setup() {
    const tagValue = ref([]);
    const inputFormBoxStyle = {
      background: "transparent",
      width: "calc(100% - 160px)",
      borderRadius: "0 36px 36px 36px",
    };

    const { agree, pool, tags } = usePool(useStore());

    return {
      tags,
      pool,
      agree,
      tagValue,
      inputFormBoxStyle,
    };
  },
};
</script>
