<template>
  <div class="state-details-section history-table" @click="toggleContent()">
    <div class="state-details-header">
      <span class="state-details-main-text">
        NFT Transfer History ({{ historyCount }})
      </span>
      <img width="20" height="10" alt="carret" :src="arrowImage" />
    </div>
    <div style="width: 100%" v-show="isActive" v-on:click.stop>
      <div class="state-details-content">
        <v-server-table
          :url="tableUrl"
          ref="historyTable"
          :columns="tableColumns"
          :options="tableOptions"
          @row-click="handleRowClick"
        >
          <template v-slot:from="{ row }">
            <span class="green">
              {{ reduceAddress(row.fromAddress, 6, 4) }}
            </span>
          </template>
          <template v-slot:to="{ row }">
            <span class="green">
              {{ reduceAddress(row.toAddress, 6, 4) }}
            </span>
          </template>
          <template v-slot:txHash="{ row }">
            {{ reduceAddress(row.transactionHash, 6, 4) }}
          </template>
          <template v-slot:date="{ row }">
            {{ formatShortDate(row.blockTimestamp) }}
          </template>
        </v-server-table>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "NftHistory",
  components: {},
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  setup: function (props) {
    const historyTable = ref(null);
    const isActive = ref(false);
    const toggleContent = () => {
      isActive.value = !isActive.value;
    };
    const historyCount = ref(null);
    const arrowImage = computed(() =>
      isActive.value
        ? require("@/Common/Icons/arrowUp.png")
        : require("@/Common/Icons/arrowDown.png")
    );
    const reduceAddress = (address, lengthLeft, lengthRight) => {
      let addressLeftPart = address.substring(0, lengthLeft);
      let addressRightPart = address.substring(42 - lengthRight, 42);
      if (address.length > 42) {
        addressLeftPart = address.substring(0, lengthLeft);
        addressRightPart = address.substring(
          address.length - lengthRight,
          address.length
        );
      }
      return `${addressLeftPart}...${addressRightPart}`;
    };
    const formatShortDate = (date) => {
      var d = new Date(date);
      return d.toLocaleDateString("en-GB");
    };

    const handleRowClick = async (event) => {
      let url;
      if (props.nft.network === "mumbai")
        url = `https://mumbai.polygonscan.com/tx/${event.row.transactionHash}`;
      if (props.nft.network === "polygon")
        url = `https://polygonscan.com/tx/${event.row.transactionHash}`;
      if (props.nft.network === "goerli")
        url = `https://goerli.etherscan.io/tx/${event.row.transactionHash}`;
      if (props.nft.network === "ethereum")
        url = `https://etherscan.io/tx/${event.row.transactionHash}`;
      window.open(url, "_blank");
    };

    const tableUrl = `/nfts/history/${props.nft.contractAddress}/${props.nft.contractId}/${props.nft.network}`;

    const tableOptions = ref({
      resizableColumns: false,
      orderBy: {
        ascending: false,
      },
      pagination: {
        virtual: false,
        chunk: 8,
      },
      responseAdapter({ data }) {
        historyCount.value = data.length;
        return {
          data: data,
        };
      },
      filterable: false,
      headings: {
        from: "From",
        to: "To",
        txHash: "Transaction Hash",
        date: "Date",
      },
      sortable: [],
    });

    const tableColumns = ["from", "to", "txHash", "date"];

    return {
      isActive,
      tableUrl,
      arrowImage,
      historyTable,
      historyCount,
      tableOptions,
      tableColumns,
      toggleContent,
      reduceAddress,
      handleRowClick,
      formatShortDate,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftHistory.scss" />
