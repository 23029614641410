<template>
  <div class="collection-form-container">
    <h3>dashboard</h3>
    <h1>Collections Info Update</h1>
    <p class="file-input-label">
      Add collection <span class="green">cover image</span>
    </p>
    <file-input
      id="collectionCoverImg"
      v-model="localCoverImg"
      name="collectionCoverImg"
      :isImageOnly="true"
      :inputVariant="'light'"
      :onChange="onFileChangedCover"
      :shouldPickCoverImage="true"
      :crossStyle="'cross_grey.svg'"
      accept="image/jpeg,image/jpg,image/png"
      previewMessageDisable="true"
    />
    <p class="file-input-label">
      Add collection <span class="green">profile image</span>
    </p>
    <file-input
      id="collectionProfileImg"
      v-model="localProfileImg"
      name="collectionProfileImg"
      :isImageOnly="true"
      :inputVariant="'light'"
      :onChange="onFileChangedProfile"
      :shouldPickCoverImage="true"
      :crossStyle="'cross_grey.svg'"
      accept="image/jpeg,image/jpg,image/png"
      previewMessageDisable="true"
    />
    <base-form
      method="post"
      id="edit-profile"
      :schema="updateCollectionSchema"
      :handleSubmit="handleSubmit"
      class="collection-form"
    >
      <div class="input-fields">
        <app-input
          id="edit-name"
          :value="collection.name"
          v-model="collection.name"
          type="text"
          maxLength="50"
          labelClass="label"
          name="name"
          labelText="Name"
          inputClass="form-box-input"
          componentClass="section-inputs"
          bottomLabel="Name of your collection"
        />
        <app-textarea
          cols="1"
          rows="4"
          type="text"
          maxLength="1000"
          labelText="Description"
          labelClass="label"
          name="description"
          id="edit-description"
          componentClass="section-inputs"
          :value="collection.description"
          v-model="collection.description"
          textAreaClass="form-box-textarea-edit-profile"
          bottomLabel="Say something about your collection"
        />
        <app-input
          id="numberOfItems"
          :value="
            collection.originalItemsNumber ? collection.originalItemsNumber : 0
          "
          v-model="collection.originalItemsNumber"
          type="text"
          labelClass="label"
          name="originalItemsNumber"
          labelText="Number of items"
          inputClass="form-box-input"
          componentClass="section-inputs"
          bottomLabel="Number of NFTs in the collection"
        />
        <app-date-picker
          label="Date Created"
          name="creationDate"
          v-model="collection.createdAt"
          bottomLabel="When was this collection created"
        />
      </div>
      <app-button
        type="submit"
        text="Save"
        class="collection-save-button"
        buttonClass="app-modal-button-inverse"
      />
    </base-form>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { onMounted, reactive, ref } from "vue";
import useToast from "@/composables/useToast";
import { updateCollectionSchema } from "../../validationSchemas/updateCollectionSchema";

// Components
import AppInput from "@/elements/AppInput";
import BaseForm from "@/components/BaseForm";
import FileInput from "@/elements/FileInput";
import AppButton from "@/elements/AppButton";
import AppTextarea from "@/elements/AppTextarea";
import AppDatePicker from "@/elements/AppDatePicker";
export default {
  name: "UpdateCollection",
  components: {
    AppButton,
    BaseForm,
    AppInput,
    FileInput,
    AppTextarea,
    AppDatePicker,
  },
  setup() {
    const store = useStore();
    const { showToast } = useToast();
    const route = useRoute();

    const collection = reactive({
      name: "",
      address: "",
      description: "",
      coverImageId: 0,
      createdAt: "",
      profileImageId: 0,
      originalItemsNumber: 0,
    });

    const collectionId = route.params.id;
    const localCoverImg = ref({});
    const localProfileImg = ref({});
    const uploadFile = async (file) => {
      const formData = new FormData();
      formData.append("file", file);
      if (file.size > 41943040) {
        showToast(
          "The size is too big, we support files up to 40MB",
          "Try uploading a smaller file or compress it",
          "Error"
        );
        return;
      }
      return await store.dispatch("nfts/storeCoverImageFile", formData);
    };

    const onFileChangedCover = async (file) => {
      if (!file.value) {
        const result = await uploadFile(file);
        collection.coverImageId = result.data.id;
      }
    };

    const onFileChangedProfile = async (file) => {
      if (!file.value) {
        const result = await uploadFile(file);
        collection.profileImageId = result.data.id;
      }
    };

    const handleSubmit = async () => {
      const data = {
        name: collection.name,
        description: collection.description,
        originalItemsNumber: Number(collection.originalItemsNumber),
        profileImageId: collection.profileImageId,
        coverImageId: collection.coverImageId,
        creationDate: collection.createdAt,
      };
      try {
        await store.dispatch("singleCollection/updateCollectionById", {
          id: collectionId,
          data,
        });
        showToast("Collection updated.", "", "Success");
      } catch (err) {
        showToast("Collection are not updated.", "", "Error");
        console.log(err);
      }
    };

    onMounted(async () => {
      await store.dispatch("singleCollection/fetchCollectionById", {
        id: collectionId,
      });
      Object.assign(
        collection,
        store.getters["singleCollection/getByKey"]("singleCollection")
      );
      collection.createdAt = new Date(collection.createdAt);
    });

    return {
      collection,
      handleSubmit,
      localCoverImg,
      localProfileImg,
      onFileChangedCover,
      onFileChangedProfile,
      updateCollectionSchema,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/updateCollection.scss"></style>
