import api from "../plugins/apiWrapper";

const loggedInInLastHour = (me) => {
  const diff = Date.now() - me.timestamp;
  return diff < 3600000;
};

export default {
  // USERS
  async fetchMe() {
    const me = JSON.parse(window.localStorage.getItem("me"));
    if (me && loggedInInLastHour(me)) return me.response;
    const response = await api.get(`/users/me`);
    window.localStorage.setItem(
      "me",
      JSON.stringify({
        response,
        timestamp: Date.now(),
      })
    );
    return response;
  },

  async fetchUsers() {
    return api.get(`/users`);
  },

  async fetchUser(id) {
    return api.get(`/users/${id}`);
  },

  async updateUser(userData, id) {
    return api.put(`/users/${id}`, userData);
  },

  async fetchNonce(publicAddress) {
    return api.get(`/getnonce/${publicAddress}`);
  },

  async verifyNonce(signature, publicAddress) {
    return api.post("/verify", { signature, publicAddress });
  },

  async signup(publicAddress) {
    return api.post("/signup", { publicAddress });
  },

  async disconnect() {
    return api.post("/disconnect");
  },
  async fetchUsersProfileStats(id) {
    return api.get(`/users/${id}/stats`);
  },

  //NFTS
  async countNftSubmissions(id) {
    return api.get(`/nfts/${id}/count-existing-submissions`);
  },

  async pinFile(formData) {
    return api.post("/nfts/pin-file", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async fetchNftList(params) {
    return api.get(`/nfts?${params}`);
  },

  async fetchUserPooledNftList(params) {
    return api.get(`/nfts/user-pooled?${params}`);
  },

  async fetchExternalNftList({ id, page }) {
    return api.get(`/users/${id}/non-theos-nfts?page=${page}`);
  },

  async storeNft(nftData) {
    return api.post("/nfts", nftData);
  },

  async updateNft(id, nftData) {
    return api.put(`/nfts/${id}`, nftData);
  },

  async deleteNft(id) {
    return api.delete(`/nfts/${id}`);
  },

  async buyNft(data) {
    return api.post("/nfts/buy", data);
  },

  async storeNftFile(formData) {
    return api.post("/file-uploads/nft", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async fetchNft(id) {
    return api.get(`/nfts/${id}`);
  },
  async fetchPool(id) {
    // Genesis pool hotfix
    if (parseInt(id) === 22) id = 28;
    return api.get(`/pools/${id}`);
  },

  async storeCoverImageFile(formData) {
    return api.post("/file-uploads/cover-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  async autogenerateCoverImage(formData) {
    return api.post("/file-uploads/autogenerate-cover", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
  async fetchMonthlyMintedNfts() {
    return api.get("/nfts/monthly-count");
  },

  //POOLS
  async storeUserProfileImageFile(formData) {
    return api.post("/file-uploads/user-profile-image", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },

  // POOLS
  async postPool(pool) {
    return api.post("/pools", pool);
  },

  async fetchPools(params) {
    return api.get(`/pools?${params}`);
  },

  async uniqueSymbol(symbol) {
    return api.get(`/pools/token-symbol/unique?tokenSymbol=${symbol}`);
  },

  // CAUSES
  async fetchSocialCauses() {
    return api.get("/causes");
  },

  async storeSocialCause(data) {
    return api.post("/causes", data);
  },

  async fetchSubmissions(poolId, params) {
    // Genesis pool hotfix
    if (parseInt(poolId) === 22) poolId = 28;
    return api.get(`/pools/${poolId}/submissions?${params}`);
  },
  async bulkUpdateSubmissionStatus(status, submission_ids) {
    return api.post(`/submissions/bulk-actions/`, {
      action: status,
      ids: submission_ids,
    });
  },
  async submissionToPool(senderId, poolIds, nftId) {
    return api.post("/submissions", {
      senderId,
      poolIds,
      nftId,
    });
  },
  async fetchPoolDepositedNfts(poolId, params) {
    // Genesis pool hotfix
    if (parseInt(poolId) === 22) poolId = 28;
    return api.get(`/pools/${poolId}/deposited-nfts?${params}`);
  },
  async fetchPoolStatistic(poolId) {
    // Genesis pool hotfix
    if (parseInt(poolId) === 22) poolId = 28;
    return api.get(`/pools/${poolId}/stats`);
  },
  async depositNftToPool(data) {
    return api.post("/pools/deposit-nft", data);
  },
  async withdrawNftFromPool(data) {
    return api.post("/pools/withdraw-nft", data);
  },
  async deletePool(id) {
    return api.delete(`/pools/${id}`);
  },

  async fetchMySubmissions() {
    return api.get("/users/me/submissions?perPage=1");
  },

  // eslint-disable-next-line no-unused-vars
  async fetchIsWhitelisted(data) {
    return api.post("/pools/address-whitelisted", data);
  },

  async postFooterEmail(data) {
    return api.post("/emails", data);
  },
  async fetchPoolGalleryStats(params) {
    return api.get(`/pools/gallery-stats?poolIds=${params}`);
  },

  async sendEmailWhenNftGifted(id, receiverAddress) {
    return api.get(
      `/nfts/${id}/gifted-nft-email?receiverAddress=${receiverAddress}`
    );
  },

  // AUCTIONS
  async createTimedAuction(data) {
    return api.post("/auctions", data);
  },
  async auctionCancel(auctionId) {
    return await api.post("/auctions/cancel", { auctionId });
  },
  async auctionAccept(auctionId) {
    return await api.post("/auctions/accept", { auctionId });
  },
  async createBid(data) {
    return api.post("/offers", data);
  },
  async getSalt(auctionId) {
    return await api.get(`/offers/salt?auctionId=${auctionId}`);
  },

  async subscribeToPushNotifications(subscription) {
    return await api.post(`/users/subscribe`, {
      subscription,
    });
  },

  //COLLECTIONS
  async fetchCollections(params) {
    return api.get(`/collections?${params}`);
  },
  async fetchCollectionById(id) {
    return await api.get(`/collections/${id}`);
  },
  async updateCollectionById(id, data) {
    return await api.patch(`/collections/${id}`, data);
  },
  async fetchCollectionsNames() {
    return api.get(`/collections/names`);
  },
};
