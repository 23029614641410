<template>
  <div class="marketplace-wrapper">
    <app-loader :isGreen="true" v-if="loadingPanels" />
    <div class="profile-panel-body" v-else>
      <collection-panel
        :data="data"
        :query="query"
        :isEmpty="data[currentPanel].total < 1"
        :loadMore="loadMore"
        :sortFunction="sortBy"
        :filterFunction="filter"
        :isChecked="isChecked"
        :filterOptions="['network', 'name']"
        :searchFunction="search"
        :loadingMore="loadingMore"
        :currentPanel="currentPanel"
        :loadingPanels="loadingPanels"
        :clearSearchFunction="clearSearch"
        :loadingCollections="loadingCollections"
      />
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted } from "vue";
import { eventBus } from "@/main";
import AppLoader from "@/elements/AppLoader";
import useCollections from "@/composables/useCollections";
import CollectionPanel from "@/components/Collections/CollectionPanel";
export default {
  name: "Collections",
  components: {
    AppLoader,
    CollectionPanel,
  },
  setup() {
    const store = useStore();
    const {
      data,
      sortBy,
      filter,
      search,
      loadMore,
      isChecked,
      clearSearch,
      loadingMore,
      currentPanel,
      loadingPanels,
      setCurrentPanel,
      initializePanels,
      loadingCollections,
    } = useCollections(store);

    onMounted(async () => {
      await initializePanels();
      await store.dispatch("contracts/fetchCoinPrice", {});
    });

    eventBus.on("initializePanels", () => {
      initializePanels();
    });

    return {
      data,
      sortBy,
      search,
      filter,
      loadMore,
      isChecked,
      clearSearch,
      loadingMore,
      currentPanel,
      loadingPanels,
      setCurrentPanel,
      loadingCollections,
    };
  },
};
</script>
<style lang="scss" scoped src="@/Common/Styles/marketplace.scss" />
