<template>
  <div class="three-dots-icon">
    <img
      v-click-outside="hidePopup"
      @click.stop="toggle(token.id)"
      src="@/Common/Icons/three_dots_icon.svg"
      :style="{ width: `${iconSize}px`, height: `${iconSize}px` }"
    />
    <div v-if="showPopup">
      <div v-if="isPublic || isForMarketplace || isForCollection">
        <div v-if="isOwner(token)">
          <div class="popup" v-if="!isAuctioned">
            <div v-if="token.status === 'listed'" @click.stop="redirectToNft()">
              Unlist NFT
            </div>
            <div @click.stop="buyBack()" v-if="token.status === 'deposited'">
              Buy Back
            </div>
            <template v-if="token.status === 'approved' && isForCollection">
              <div @click.stop="depositToPool()">Submit in a pool</div>
              <div @click.stop="listNft(token.id)">List</div>
            </template>
          </div>
          <div v-else>
            <div
              class="popup"
              v-if="!token.auction.highestBidderId"
              @click="unlist(token)"
            >
              Unlist
            </div>
            <div
              v-else
              class="popup"
              :class="[reservePriceReached ? 'disabled' : '']"
              @click.stop="
                reservePriceReached ? null : toggleAcceptOfferFlag(token)
              "
            >
              Sell To Top Bidder
            </div>
          </div>
        </div>
        <div v-else>
          <div
            class="popup"
            v-if="token.status === 'approved' && isForCollection"
          >
            <div @click.stop="redirectToNft()">View NFT</div>
          </div>
          <div
            class="popup"
            v-else-if="!isAuctioned"
            @click.stop="
              token.status === 'deposited'
                ? buyNft(token)
                : toggleBuyListed(token)
            "
          >
            Buy NFT
          </div>
          <div
            class="popup"
            v-else-if="isAuctioned && !isEnded"
            @click.stop="bidOnNft(token)"
          >
            Place a bid
          </div>
        </div>
      </div>
      <div v-else>
        <div class="popup" v-if="token.status === 'approved'">
          <div @click.stop="depositToPool()">Submit in a pool</div>
          <div @click.stop="listNft(token.id)">List</div>
        </div>
        <div
          class="popup"
          @click.stop="redirectToNft()"
          v-if="token.status === 'listed'"
        >
          Unlist NFT
        </div>
        <div
          class="popup"
          @click.stop="buyBack()"
          v-if="token.status === 'deposited'"
        >
          Buy Back
        </div>
        <div v-if="isAuctioned">
          <div
            v-if="!token.auction.highestBidderId"
            class="popup"
            :class="{ isEnded }"
            @click="unlist(token)"
          >
            Unlist
          </div>
          <div
            v-else
            class="popup"
            :class="[reservePriceReached ? 'disabled' : '']"
            @click.stop="
              reservePriceReached ? null : toggleAcceptOfferFlag(token)
            "
          >
            Sell To Top Bidder
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { eventBus } from "@/main";
import { ethereumService } from "@/main";
import { computed, ref, onMounted } from "vue";
import vClickOutside from "click-outside-vue3";

import useBid from "@/composables/useBid";
import useAuth from "@/composables/useAuth";
import useRouter from "@/composables/useRouter";
import useDeposit from "@/composables/useDeposit";
import useListNft from "@/composables/useListNft";
import useAuctions from "@/composables/useAuctions";
import useProfileView from "@/composables/useProfileView";
import useMarketplace from "@/composables/useMarketplace";
import useBuyNftModal from "@/composables/useBuyNftModal";
import useNetworkData from "@/composables/useNetworkData";
import useBuyListedNft from "@/composables/useBuyListedNft";
import useWithdrawModal from "@/composables/useWithdrawModal";
import useInsufficientFunds from "@/composables/useInsufficientFunds";

export default {
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    external: {
      type: Boolean,
      default: false,
    },
    token: {
      type: Object,
      default: () => {},
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    isForCollection: {
      type: Boolean,
      default: false,
    },
    iconSize: {
      type: Number,
      default: 32,
    },
  },
  setup(props) {
    const store = useStore();
    const { goTo } = useRouter();
    const showPopup = ref(false);
    const { listNft } = useListNft(store);
    const { registeredUser } = useAuth(store);
    const { updateChosenNft } = useDeposit(store);
    const { currentPanel } = useProfileView(store);
    const { share, buyNft } = useMarketplace(store);
    const { isNftOnCurrentNetwork } = useNetworkData(ethereumService);

    const { bidOnNft } = useBid(store);

    const { userBalance, fetchUserBalance, toggleWithdrawFlag } =
      useWithdrawModal(store);

    const { toggleInsufficientFundsModal } =
      useInsufficientFunds(ethereumService);

    const isOwner = (token) =>
      registeredUser.value.id === token.currentOwner?.id;

    const { isAuctioned, isEnded, isRPMet, hasBids } = useAuctions(props.token);

    const { toggleBuyListed } = useBuyListedNft(store);
    const me = computed(() => store.getters["auth/getRegisteredUser"]);
    const toggle = (value) => {
      if (
        ["deposited", "listed", "auctioned"].includes(props.token.status) ||
        !props.isPublic
      )
        showPopup.value = !showPopup.value;
      eventBus.emit("isIdEqual", value);
    };
    const hidePopup = () => {
      showPopup.value = false;
    };

    const depositToPool = () => {
      if (isNftOnCurrentNetwork(props.token)) {
        goTo({
          name: "DepositNft",
          query: { profileNftId: props.token.id },
        });
        return updateChosenNft(props.token);
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    const buyBack = async () => {
      if (isNftOnCurrentNetwork(props.token)) {
        await fetchUserBalance(
          props.token.pools[0].tokenAddress,
          me.value.publicAddress
        );
        if (
          Number(userBalance.value) >= Number(props.token.pools[0].basePrice)
        ) {
          await store.dispatch("withdraw/fetchNft", props.token.id);
          return toggleWithdrawFlag();
        }
        return await toggleInsufficientFundsModal(
          props.token,
          userBalance.value
        );
      }
      return store.dispatch("auth/commitByKey", {
        switchNetworkModalFlag: true,
      });
    };

    const redirectToNft = () => {
      return goTo({
        name: "NftDetails",
        params: { id: props.token.id },
      });
    };

    onMounted(() => {
      eventBus.on("isIdEqual", (val) => {
        if (val !== props?.token?.id) {
          hidePopup();
        }
      });
    });

    const unlist = async (token) => {
      if (hasBids) return;
      await store.dispatch("auctions/auctionCancel", token.auction.auctionId);
    };

    const sellTopBidder = async (token) =>
      await store.dispatch("auctions/auctionAccept", token.auction.auctionId);

    const { toggleAcceptOfferFlag } = useBuyNftModal(store);

    const reservePriceReached = computed(() =>
      props.token.auction?.reservedPrice
        ? props.token.sellPrice >= props.token.auction?.reservedPrice
        : null
    );

    return {
      goTo,
      share,
      toggle,
      unlist,
      buyNft,
      hasBids,
      isEnded,
      buyBack,
      isOwner,
      listNft,
      isRPMet,
      bidOnNft,
      hidePopup,
      showPopup,
      isAuctioned,
      currentPanel,
      sellTopBidder,
      redirectToNft,
      depositToPool,
      registeredUser,
      toggleBuyListed,
      reservePriceReached,
      toggleAcceptOfferFlag,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftCard.scss" />
