<template>
  <div class="date-picker">
    <label class="label">{{ label }}</label>
    <input
      :id="name"
      hidden
      :name="name"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      @input="handleChange"
      @blur="handleBlur"
    />
    <datepicker
      v-model="inputValue"
      name="name"
      class="form-box-input"
      inputFormat="dd.MM.yyyy"
      :clearable="false"
    >
    </datepicker>
    <div
      v-if="bottomLabel"
      style="display: flex; justify-content: space-between"
    >
      <div
        v-show="!errorMessage"
        class="description-num-chars"
        :class="disabled ? disabledClass : ''"
      >
        {{ bottomLabel }}
      </div>
      <p
        v-if="bottomLabel"
        :class="[errorMessage ? 'validation-error-message' : '']"
        v-show="errorMessage"
      >
        {{ errorMessage }}
      </p>
    </div>
    <p
      v-if="!bottomLabel"
      :class="[errorMessage ? 'validation-error-message' : '']"
      v-show="errorMessage"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>
<script>
import Datepicker from "vue3-datepicker";
import { toRef } from "vue";
import { useField } from "vee-validate";

import {
  endOfMonth,
  endOfYear,
  startOfMonth,
  startOfYear,
  subMonths,
} from "date-fns";

export default {
  name: "UpdateCollection",
  props: {
    modelValue: {
      type: [String, Date],
      default: () => "",
    },
    name: {
      type: String,
      default: "",
    },
    bottomLabel: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    label: {
      type: String,
      default: "",
    },
    maxDate: {
      type: String,
      default: () => "",
    },
    minDate: {
      type: String,
      default: () => "",
    },
    minTime: {
      type: Object,
      default: () => ({}),
    },
    enableTimePicker: {
      type: Boolean,
      default: false,
    },
    pickRange: {
      type: Boolean,
      default: false,
    },
    presetRanges: {
      type: Array,
      default: () => [
        {
          label: "Today",
          range: [new Date(), new Date()],
        },
        {
          label: "Current Month",
          range: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          label: "Last month",
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1)),
          ],
        },
        {
          label: "Current Year",
          range: [startOfYear(new Date()), endOfYear(new Date())],
        },
      ],
    },
  },
  components: {
    Datepicker,
  },
  setup(props) {
    const name = toRef(props, "name");
    const {
      value: inputValue,
      errorMessage,
      handleBlur,
      handleChange,
    } = useField(name, undefined, {
      initialValue: props.modelValue,
    });

    return {
      inputValue,
      errorMessage,
      handleBlur,
      handleChange,
    };
  },
};
</script>
<style scoped lang="scss" src="@/Common/Styles/appDatePicker.scss" />
