<template>
  <div
    class="network-badge"
    :style="{ width: `${cardSize * 0.6}px`, height: `${cardSize * 0.6}px` }"
  >
    <img :src="networkBadgeUrl()" :alt="collection.network" />
  </div>
</template>

<script>
export default {
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
    cardSize: {
      type: Number,
      default: 32,
    },
  },
  setup(props) {
    const networkBadgeUrl = () =>
      ["mumbai", "polygon"].includes(props.collection.network)
        ? require("@/Common/Icons/polygonBadge.svg")
        : require("@/Common/Icons/ethereumBadge.svg");

    return {
      networkBadgeUrl,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/networkBadge.scss" />
