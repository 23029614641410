<template>
  <div class="deposit-panel-filter" :class="[address ? 'not-connected' : '']">
    <div class="deposit-panel-filter-wrapper">
      <div class="deposit-panel-filter-overlay" @click="toggle()" />
      <app-input
        disabled
        placeholder="Sort"
        inputClass="app-modal-input-sort"
        inputIconLeftClass="filter-icon"
        :inputStyle="'padding-left: 35px;'"
        :inputIconRightClass="{
          'chevron-icon': true,
          active: showFilters,
        }"
      />
    </div>
    <teleport to="body">
      <div
        class="filtering-options-overlay"
        v-if="showFilters"
        @click="toggle"
      />
    </teleport>
    <div class="filtering-options" v-if="showFilters">
      <h3 class="filtering-options-heading">Sort by</h3>
      <p
        v-for="(option, index) in sortOptions"
        :key="index"
        class="filtering-option"
        @click="
          sortFunction(option.value);
          setFiltersFlag(false);
        "
      >
        {{ option.title }}
      </p>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import useCollections from "../../composables/useCollections";

export default {
  components: {
    AppInput,
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    sortFunction: {
      type: Function,
      default: () => {},
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup() {
    const showFilters = ref(false);
    const filtersFlag = ref(false);
    const { isChecked, filter } = useCollections(useStore());

    const toggle = () => {
      showFilters.value = !showFilters.value;
    };

    const setFiltersFlag = (flag) => {
      filtersFlag.value = flag;
    };
    return {
      toggle,
      filter,
      isChecked,
      showFilters,
      setFiltersFlag,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/Sort.scss" />
