<template>
  <div class="profile-panel-body-wrapper-no-padding">
    <collection-panel-filters
      :sortOptions="sortOptions"
      :sortFunction="sortFunction"
      :searchFunction="searchFunction"
      :isForMarketplace="false"
      :isChecked="isChecked"
      :filterFunction="filterFunction"
      :filterOptions="filterOptions"
      :clearSearchFunction="clearSearchFunction"
    />
    <app-loader :isGreen="true" v-if="loadingCollections || loadingPanels" />
    <template v-else-if="isEmpty">
      <no-data
        :image="require('@/Common/Icons/spyglass.svg')"
        :title="`Sorry, we don't have any results.`"
      />
    </template>
    <template v-else>
      <div class="profile-panel-body-content">
        <template v-for="collection in collections" :key="collection.id">
          <collection-panel-card
            :collection="collection"
            @click="cardClickHandler(collection)"
          />
        </template>
      </div>
      <load-more
        currentPanel="all"
        :data="data"
        :loadMore="loadMore"
        :loadingMore="false"
      />
    </template>
  </div>
</template>
<script>
import { computed } from "vue";
import { useStore } from "vuex";
import AppLoader from "@/elements/AppLoader";
import useRouter from "@/composables/useRouter";
import NoData from "@/components/Profile/Panels/NoData";
import LoadMore from "@/components/Profile/Panels/LoadMore";
import CollectionPanelCard from "@/components/Collections/CollectionPanelCard";
import CollectionPanelFilters from "@/components/Collections/CollectionPanelFilters.vue";

export default {
  components: {
    NoData,
    LoadMore,
    AppLoader,
    CollectionPanelCard,
    CollectionPanelFilters,
  },
  props: {
    filterFunction: {
      type: Function,
      default: () => {},
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    isChecked: {
      type: Function,
      default: () => {},
    },
    currentPanel: {
      type: String,
      default: "",
    },
    loadingMore: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    loadingCollections: {
      type: Boolean,
      default: false,
    },
    loadingPanels: {
      type: Boolean,
      default: false,
    },
    isPublic: {
      type: Boolean,
      default: false,
    },
    searchFunction: {
      type: Function,
      default: () => {},
    },
    clearSearchFunction: {
      type: Function,
      default: () => {},
    },
    sortFunction: {
      type: Function,
      default: () => {},
    },
    loadMore: {
      type: Function,
      default: () => {},
    },
    data: {
      type: Object,
      default: () => ({ data: [], total: 0 }),
    },
  },
  setup(props) {
    const store = useStore();
    const { goTo } = useRouter();
    const sortOptions = [
      {
        value: {
          orderBy: "id",
          orderDirection: "desc",
        },
        title: "Newest",
      },
      {
        value: {
          orderBy: "id",
          orderDirection: "asc",
        },
        title: "Oldest",
      },
    ];
    const me = computed(() => store.getters["auth/getRegisteredUser"]);
    const isOwner = (token) => me.value.id === token.currentOwner.id;
    const collections = computed(() => props.data[props.currentPanel].data);

    const cardClickHandler = (card) =>
      goTo({
        name: "ViewCollection",
        params: { id: card.id },
      });

    return {
      isOwner,
      collections,
      sortOptions,
      cardClickHandler,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/collectionPanels.scss" />
