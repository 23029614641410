<template>
  <div
    v-if="nftProperties"
    class="state-details-section"
    @click="toggleContent()"
  >
    <div class="state-details-header">
      <span class="state-details-main-text"> NFT Properties </span>
      <img width="20" height="10" alt="carret" :src="arrowImage" />
    </div>
    <div style="width: 100%" v-show="isActive" v-on:click.stop>
      <div class="state-details-content">
        <div
          class="item"
          v-for="(property, index) in nftProperties"
          :key="index"
        >
          <div class="tooltip">{{ property.key || property.trait_type }}</div>
          <!--          <p class="caption">{{ property.value }}</p>-->
          <h3>
            {{ property.key }}
          </h3>
          <h4>{{ property.value }}</h4>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "vue";

export default {
  name: "StateDetails",
  components: {},
  props: {
    nft: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const isActive = ref(false);
    const toggleContent = () => {
      isActive.value = !isActive.value;
    };
    const arrowImage = computed(() =>
      isActive.value
        ? require("@/Common/Icons/arrowUp.png")
        : require("@/Common/Icons/arrowDown.png")
    );
    const nftProperties = computed(() => props.nft.metaData?.attributes || props.nft.metaData?.traits);
    return {
      isActive,
      arrowImage,
      nftProperties,
      toggleContent,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/nftProperties.scss" />
