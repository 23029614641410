<template>
  <input-form-box>
    <section>
      <div class="tags-royalties-wrap">
        <app-input-tag
          type="text"
          name="Tags"
          labelText="Tags"
          v-model="tagValue"
          tagSuggestionText="Tags boost NFT and creator reach. Ex."
          labelClass="label"
          inputTagClass="form-box-input"
          tagInputWrap="tag-input-wrap"
          class="tags-royalties-tag"
          :suggestions="['Art', 'Abstract']"
          placeholder="Type to search tags"
        />
        <app-input
          type="number"
          name="Royalties"
          @input="onInput"
          labelClass="label"
          :suggestions="true"
          @keydown="filterKey"
          labelText="Royalties"
          class="tags-royalties"
          v-model="localRoyalties"
          inputClass="form-box-input"
          placeholder="Add a percentage"
          :percents="[
            { value: 2.5, text: '2.5%' },
            { value: 5, text: '5%' },
            { value: 10, text: '10%' },
          ]"
          infoText="Receive this percentage over <br>
                    selling price each time your NFT is bought. "
        />
      </div>
      <div class="voice-over-wrap">
        <app-input
          type="text"
          labelClass="label"
          name="AlternativeText"
          placeholder="Describe your media in detail"
          inputClass="form-box-input"
          labelText="Alternative Text"
          v-model="localAlternativeText"
          componentClass="section-inputs"
          bottomLabel="Try to describe it as specifically as possible"
          infoText="Text that will be used in VoiceOver <br>
                    for people with disabilities"
        />
      </div>
    </section>
    <div class="form-box-buttons-controls">
      <app-button
        text="Back"
        buttonClass="app-modal-button"
        @click.prevent="decrementStep()"
      />
      <app-button
        type="submit"
        text="Preview"
        form="mint-nft"
        @click="commitToStore()"
        buttonClass="app-modal-button-inverse"
      />
    </div>
  </input-form-box>
</template>
<script>
import { useStore } from "vuex";
import { computed, ref } from "vue";
import AppInputTag from "@/elements/AppInputTag";
import AppInput from "@/elements/AppInput.vue";
import AppButton from "@/elements/AppButton.vue";
import useMinting from "../../composables/useMinting";
import InputFormBox from "@/elements/InputFormBox.vue";
export default {
  name: "MintDetails",
  components: {
    AppInput,
    AppButton,
    AppInputTag,
    InputFormBox,
  },
  setup() {
    const store = useStore();
    const tagValue = ref([]);
    const localRoyalties = ref(null);
    const localAlternativeText = ref("");
    const lastValidInputValue = ref("");

    const { royalties, alternativeText, decrementStep, updateBulk } =
      useMinting(store);

    if (alternativeText.value)
      localAlternativeText.value = alternativeText.value;

    if (royalties.value) localRoyalties.value = royalties.value;

    const tags = computed(() => store.getters["tagsValues/getByKey"]("tags"));

    const commitToStore = () =>
      updateBulk({
        tags: tags.value,
        royalties: localRoyalties.value,
        alternativeText: localAlternativeText.value,
      });
    const filterKey = (e) => {
      const key = e.key;
      if (key === "e") return e.preventDefault();
      if (key === "-" || key === "+") return e.preventDefault();
    };

    const onInput = (e) => {
      if (navigator.userAgent.indexOf("Firefox") != -1) {
        if (e.target.value !== "") {
          lastValidInputValue.value = e.target.value;
        } else if (e.inputType.match(/delete/g)) {
          lastValidInputValue.value = "";
        } else {
          e.target.value = lastValidInputValue.value;
        }
      }
      if (e.target.value !== "") {
        localRoyalties.value = parseFloat(e.target.value);
      }
    };

    return {
      tags,
      onInput,
      tagValue,
      filterKey,
      decrementStep,
      commitToStore,
      localRoyalties,
      lastValidInputValue,
      localAlternativeText,
    };
  },
};
</script>
