import { eventBus } from "@/main";
import { ref, computed } from "vue";
import { ethereumService } from "@/main";
import useStoreFunctions from "@/composables/useStoreFunctions";

const currentPanel = ref("all");
const MILLISECONDS_MINIMUM_CARD_LOADING = 0;

const useCollections = (store, storeFunctions = useStoreFunctions) => {
  const {
    computeStore: collectionsGet,
    dispatchStore: collectionsDispatch,
    commitByKey: collectionsCommitByKey,
  } = storeFunctions("collections", store);
  const data = collectionsGet("data");
  const loadingPanels = collectionsGet("loadingPanels");
  const loadingCollections = collectionsGet("loadingCollections");
  const loadingMore = collectionsGet("loadingMore");

  const searchQuery = collectionsGet("searchString");

  const initializePanels = async () => {
    await collectionsDispatch("resetState");
    await collectionsCommitByKey({ loadingPanels: true });
    await collectionsDispatch("fetchAllCollections");
    await collectionsCommitByKey({ loadingPanels: false });
    await collectionsCommitByKey({ loadingMore: false });
  };

  const search = async (searchStringInput) => {
    if (loadingCollections.value) return;
    await collectionsDispatch("resetPage");
    await collectionsCommitByKey({ loadingCollections: true });
    await collectionsCommitByKey({ searchString: searchStringInput });
    await collectionsDispatch("resetDataArray", currentPanel.value);
    const searchParams = {
      search: searchStringInput,
    };
    await collectionsDispatch("fetchAllCollections", searchParams);
    await collectionsCommitByKey({ loadingCollections: false });
    if (searchStringInput === "") {
      eventBus.emit("changeCollectionsSearchFlag", false);
    }
  };

  const clearSearch = async () => {
    await collectionsDispatch("resetDataArray", currentPanel.value);
    await collectionsDispatch("resetPage");
    await collectionsDispatch("clearSearch");
    await collectionsCommitByKey({ loadingCollections: true });
    await collectionsDispatch("fetchAllCollections");
    await collectionsCommitByKey({ loadingCollections: false });
  };

  const sortBy = async (sort) => {
    await collectionsDispatch("resetPage");
    await collectionsDispatch("resetDataArray", currentPanel.value);
    await collectionsDispatch("setSort", sort);
    await collectionsCommitByKey({ loadingCollections: true });
    await collectionsDispatch("fetchAllCollections");
    await collectionsCommitByKey({ loadingCollections: false });
  };

  const elementExist = async (prevQuery, filter) => {
    let indexOf = prevQuery.indexOf(filter);
    if (indexOf >= 0) {
      return prevQuery.splice(indexOf, 1);
    }
    if (indexOf === -1) {
      return prevQuery.push(filter);
    }
    return false;
  };

  const query = computed(() =>
    store.getters["collections/getByKey"]("queries")
  );

  const filter = async (filter) => {
    const prevQuery = { ...query.value };
    eventBus.emit("changeMarketplaceFilterAndSortFlag", true);
    await collectionsDispatch("resetPage");
    await collectionsDispatch("resetDataArray", currentPanel.value);

    if (filter.type === "price") {
      await elementExist(prevQuery.priceRange, filter.priceRange);
      await collectionsDispatch("setFilter", {
        priceRange: prevQuery.priceRange,
      });
    }

    if (filter.type === "names") {
      await elementExist(prevQuery.names, filter.value);
      await collectionsDispatch("setFilter", {
        names: prevQuery.names,
      });
    }

    if (filter.type === "media") {
      await elementExist(prevQuery.media, filter.value);
      await collectionsDispatch("setFilter", {
        media: prevQuery.media,
      });
    }

    if (filter.type === "network") {
      const networkFilter = ethereumService.availableNetworks[filter.value];
      await elementExist(prevQuery.networks, networkFilter);
      await collectionsDispatch("setFilter", {
        networks: prevQuery.networks,
      });
    }

    if (filter.type === "clear") {
      await collectionsDispatch("setFilter", {
        media: [],
        priceRange: [],
        networks: [],
      });
    }
    await collectionsCommitByKey({ loadingCollections: true });
    await collectionsDispatch("fetchAllCollections");
    await collectionsCommitByKey({ loadingCollections: false });

    if (prevQuery.media.length === 0 && prevQuery.priceRange.length === 0) {
      eventBus.emit("changeMarketplaceFilterAndSortFlag", false);
    }
  };

  const isChecked = (type, value) => {
    if (type === "price" && query.value.priceRange.includes(value)) {
      return true;
    }

    if (type === "network") {
      const networkFilter = ethereumService.availableNetworks[value];
      return query.value.networks.includes(networkFilter);
    }

    if (type === "names" && query.value.names.includes(value)) {
      return true;
    }

    return type === "media" && query.value.media.includes(value);
  };

  const activeSort = computed(
    () => `${query.value.orderBy}_${query.value.orderDirection.toLowerCase()}`
  );

  const loadMore = async () => {
    if (loadingMore.value) return;
    await collectionsCommitByKey({ loadingMore: true });
    const loadParams = {};
    if (searchQuery.value) {
      loadParams["search"] = searchQuery.value;
    }
    setTimeout(async () => {
      await collectionsDispatch("incrementPage");
      await collectionsDispatch("fetchAllCollections", loadParams);
      await collectionsCommitByKey({ loadingMore: false });
    }, MILLISECONDS_MINIMUM_CARD_LOADING);
  };

  return {
    data,
    filter,
    sortBy,
    search,
    loadMore,
    isChecked,
    activeSort,
    clearSearch,
    loadingMore,
    loadingCollections,
    currentPanel,
    loadingPanels,
    initializePanels,
  };
};

export default useCollections;
