import api from "@/services/api";
import queryString from "query-string";

const initialQueries = () => ({
  page: 1,
  media: [],
  perPage: 6,
  orderBy: "updatedAt",
  orderDirection: "DESC",
  networks: [],
  priceRange: [],
  attributes: [],
});
const initialData = () => ({ data: [], total: 0 });
const initialState = () => ({
  errors: [],
  loadingCollections: false,
  loadingPanels: false,
  loadingMore: false,
  loadingNfts: false,
  collectionNfts: { all: initialData() },
  queries: initialQueries(),
  singleCollection: {
    id: null,
    attributes: [],
    name: "",
    address: "",
    creator: "",
    type: "",
    description: "",
    originalItemsNumber: null,
    coverImageId: null,
    profileImageId: null,
    creationDate: null,
    network: "",
    createdAt: "",
    updatedAt: "",
    coverImage: null,
    profileImage: null,
  },
  traitFilterOptions: [],
});

const state = initialState();

const getters = {
  getByKey: (state) => (key) => state[key],
};

const mutations = {
  pushError(state, error) {
    state.errors.push(error);
  },

  resetDataArray(state, dataName) {
    state.collectionNfts[dataName].data = [];
  },

  resetState(state) {
    Object.assign(state, initialState());
  },

  incrementPage(state) {
    state.queries.page = state.queries.page + 1;
  },

  resetQueries(state) {
    Object.assign(state.queries, initialQueries());
    state.searchString = "";
  },

  resetPage(state) {
    state.queries.page = 1;
  },

  resetData(state, dataName) {
    Object.assign(state.collectionNfts[dataName], initialData());
  },

  commitByKey(state, object) {
    Object.keys(object).forEach((key) => (state[key] = object[key]));
  },
};
const actions = {
  setByKey({ commit }, obj) {
    commit("setByKey", obj);
  },
  commitByKey({ commit }, object) {
    commit("commitByKey", object);
  },
  resetState({ commit }) {
    commit("resetState");
  },
  resetQueries({ commit }) {
    commit("resetQueries");
  },
  incrementPage({ commit }) {
    commit("incrementPage");
  },
  pushError({ commit }, error) {
    commit("pushError", error);
  },

  resetData({ commit }, dataName) {
    commit("resetData", dataName);
  },

  resetDataArray({ commit }, dataName) {
    commit("resetDataArray", dataName);
  },

  resetPage({ commit }) {
    commit("resetPage");
  },
  async fetchNftsForCollection({ commit, state }, params) {
    try {
      const attributesParams = {};
      state.queries.attributes.map((attribute) => {
        attributesParams[attribute.traitType] = attribute.traitValue;
      });
      const response = await api.fetchNftList(
        queryString.stringify({
          collectionId: state.singleCollection.id,
          ...params,
          ...state.queries,
          networks: state.queries.networks.toString(),
          media: state.queries.media.toString(),
          priceRange: state.queries.priceRange.toString(),
          attributes: JSON.stringify(attributesParams),
          // attributes: JSON.stringify(state.queries.attributes),
        })
      );
      const { data, total } = response.data;
      commit("commitByKey", {
        collectionNfts: {
          ...state.collectionNfts.all,
          all: {
            data: state.collectionNfts.all.data.concat(data),
            total,
          },
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },
  async setFilter({ commit, state }, params) {
    try {
      commit("commitByKey", {
        queries: {
          ...state.queries,
          ...params,
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async setSort({ commit, state }, params) {
    try {
      commit("commitByKey", {
        queries: {
          ...state.queries,
          ...params,
        },
      });
    } catch (error) {
      commit("pushError", error);
    }
  },

  async clearSearch({ commit }) {
    try {
      commit("commitByKey", {
        searchString: "",
      });
    } catch (error) {
      commit("pushError", error);
    }
  },
  async fetchCollectionById({ commit }, { id }) {
    try {
      const response = await api.fetchCollectionById(id);
      commit("commitByKey", { singleCollection: response.data });
      commit("commitByKey", { traitFilterOptions: response.data.attributes });
    } catch (error) {
      console.log(error);
      commit("pushError", error);
    }
  },
  async updateCollectionById({ commit }, { id, data }) {
    try {
      return await api.updateCollectionById(id, data);
    } catch (error) {
      console.log(error);
      commit("pushError", error);
      throw new Error(error.response.data.message);
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
};
