<template>
  <div :class="componentClass">
    <label :for="id" class="description" :class="labelClass"
      >{{ labelText }}
    </label>
    <textarea
      class="textarea"
      v-bind="$attrs"
      v-model="inputValue"
      @input="$emit('update:modelValue', $event.target.value)"
      :id="id"
      :name="name"
      :placeholder="placeholder"
      :disabled="disabled"
      :class="[textAreaClass, errorMessage ? 'error-message-border' : '']"
      :style="textAreaStyle"
      :maxlength="maxLength"
    >
    </textarea>
    <div
      v-if="bottomLabel"
      style="display: flex; justify-content: space-between"
    >
      <div
        v-show="!errorMessage || meta.valid"
        class="description-num-chars"
        :class="disabled ? disabledClass : ''"
      >
        {{ bottomLabel }}
      </div>
      <p
        v-if="bottomLabel"
        :class="[messageClass, errorMessage ? 'validation-error-message' : '']"
        v-show="errorMessage || !meta.valid"
      >
        {{ errorMessage }}
      </p>
    </div>
    <p
      v-if="!bottomLabel"
      :class="[messageClass, errorMessage ? 'validation-error-message' : '']"
      v-show="errorMessage || !meta.valid"
    >
      {{ errorMessage }}
    </p>
    <span v-if="maxLength" class="description-num-chars">
      {{ inputValue ? inputValue.length : 0 }}/{{ maxLength }}
    </span>
  </div>
</template>

<script>
import { onMounted, onUnmounted, toRefs, watch } from "vue";
import { useField } from "vee-validate";
import useInputErrors from "../composables/useInputErrors";
import { useStore } from "vuex";

export default {
  name: "AppTextarea",
  props: {
    id: {
      type: String,
    },
    name: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    textAreaClass: {
      type: String,
      default: "",
    },
    componentClass: {
      type: String,
      default: "",
    },
    textAreaStyle: {
      type: Object,
      default: () => {},
    },
    maxLength: {
      type: Number,
      default: null,
    },
    bottomLabel: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { watchErrorMessage, clearErrorMessages } = useInputErrors(
      useStore()
    );
    let mintNftWatcher;

    const {
      value: inputValue,
      errorMessage,
      meta,
    } = useField(props.name, undefined, {
      initialValue: props.modelValue,
    });

    let { modelValue } = toRefs(props);
    watch(
      () => modelValue,
      (modelValue) => {
        inputValue.value = modelValue.value;
      },
      { deep: true }
    );

    onMounted(() => {
      mintNftWatcher = watchErrorMessage(errorMessage, "minting");
    });
    onUnmounted(() => {
      mintNftWatcher();
      clearErrorMessages("minting");
    });

    return {
      errorMessage,
      inputValue,
      meta,
    };
  },
};
</script>

<style lang="scss" scoped></style>
