<template>
  <div class="app-card-content" ref="cardContentBox">
    <div class="app-card-content-description">
      <div
        class="card-title-text"
        :class="[
          isStringTooLong(collection.name, MAX_NFT_NAME_LENGTH)
            ? 'is-too-long'
            : '',
        ]"
      >
        <div class="nft-name-tooltip">
          {{ collection.name }}
        </div>
        <p
          ref="cardTitle"
          class="app-card-title"
          :class="[
            isStringTooLong(collection.name, MAX_NFT_NAME_LENGTH)
              ? 'is-too-long'
              : '',
          ]"
        >
          {{ formatTitleName(collection.name, MAX_NFT_NAME_LENGTH) }}
        </p>
        <p ref="cardSubTitle" class="app-card-subtitle">
          {{ collection.loaded || 0 }}
          loaded.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
const MAX_NFT_NAME_LENGTH = 22;

import useUtilities from "@/composables/useUtilities";
import { computed, ref, onMounted, onBeforeUnmount } from "vue";
export default {
  props: {
    collection: {
      type: Object,
      default: () => {},
    },
  },
  setup(props) {
    const cardTitle = ref(null);
    const cardSubTitle = ref(null);
    const cardContentBox = ref(null);
    const poolPlaceholder = ref(null);

    const { isStringTooLong, toFixedIfNecessary } = useUtilities();
    //   TODO: Return total NFTs in pool from backend
    const spanCapacity = computed(() => "full-capacity");

    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        let contentBoxSize = Array.isArray(entry.contentBoxSize)
          ? entry.contentBoxSize[0].inlineSize
          : entry.contentBoxSize.inlineSize;

        if (props.isBig) contentBoxSize /= 2;

        if (cardTitle.value) {
          cardTitle.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.0458
          )}px`;
          cardTitle.value.style.marginBottom = `${Math.floor(
            contentBoxSize * 0.015
          )}px`;
          cardTitle.value.style.marginTop = `${Math.floor(
            contentBoxSize * 0.0348
          )}px`;
          cardTitle.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.257
          )}px`;
        }
        if (cardSubTitle.value) {
          cardSubTitle.value.style.fontSize = `${Math.floor(
            contentBoxSize * 0.035
          )}px`;
          cardSubTitle.value.style.marginBottom = `${Math.floor(
            contentBoxSize * 0.019
          )}px`;
          cardSubTitle.value.style.marginLeft = `${Math.floor(
            contentBoxSize * 0.257
          )}px`;
        }
      }
    });

    onMounted(() => {
      resizeObserver.observe(cardContentBox.value);
    });

    onBeforeUnmount(() => resizeObserver.unobserve(cardContentBox.value));

    const formatTitleName = (name, maxLength) =>
      name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;

    return {
      cardTitle,
      cardSubTitle,
      spanCapacity,
      cardContentBox,
      poolPlaceholder,
      formatTitleName,
      isStringTooLong,
      toFixedIfNecessary,
      MAX_NFT_NAME_LENGTH,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/collectionCard.scss" />
