<template>
  <div class="deposit-panel-filter" :class="[address ? 'not-connected' : '']">
    <div class="deposit-panel-filter-wrapper">
      <div class="deposit-panel-filter-overlay" @click="toggle()" />
      <app-input
        disabled
        :placeholder="showTraitrsFilter ? 'Traits' : 'Filter'"
        inputClass="app-modal-input-sort"
        inputIconLeftClass="filter-icon"
        :inputStyle="'padding-left: 35px;'"
        :inputIconRightClass="{
          'chevron-icon': true,
          active: showFilters,
        }"
      />
    </div>
    <teleport to="body">
      <div
        class="filtering-options-overlay"
        v-if="showFilters"
        @click="toggle"
      />
    </teleport>
    <div class="filtering-options" v-if="showFilters && showTraitrsFilter">
      <template v-for="(item, index) in traitsOptions" :key="index">
        <h3 class="filtering-options-heading">{{ item.type }}</h3>
        <app-checkbox
          v-for="(_item, _index) in item.values"
          :key="_index"
          :id="index"
          :labelText="_item"
          :styleClass="'marketplace'"
          class="filtering-option-checkbox"
          :checked="
            isChecked('traits', { traitType: item.type, traitValue: _item })
          "
          @click="
            filterFunction({
              type: 'traits',
              value: { traitType: item.type, traitValue: _item },
            })
          "
        />
      </template>
    </div>
    <div class="filtering-options" v-if="showFilters && !showTraitrsFilter">
      <h2 class="filtering-options-heading">Filter by</h2>
      <h3 class="filtering-options-heading">Network</h3>
      <app-checkbox
        id="6"
        class="filtering-option-checkbox"
        :styleClass="'marketplace'"
        labelText="Ethereum"
        :checked="isChecked('network', 'ethereum')"
        @click="filterFunction({ type: 'network', value: 'ethereum' })"
      />
      <app-checkbox
        id="7"
        labelText="Polygon"
        :styleClass="'marketplace'"
        class="filtering-option-checkbox"
        :checked="isChecked('network', 'polygon')"
        @click="filterFunction({ type: 'network', value: 'polygon' })"
      />
      <template v-if="filterOptions.includes('name')">
        <h3 class="filtering-options-heading">Collection name</h3>
        <app-checkbox
          v-for="(item, index) in nameFilterValues.data"
          :key="index"
          :id="index"
          :labelText="item"
          :styleClass="'marketplace'"
          class="filtering-option-checkbox"
          :checked="isChecked('names', item)"
          @click="filterFunction({ type: 'names', value: item })"
        />
      </template>
      <template v-if="filterOptions.includes('media')">
        <h3 class="filtering-options-heading">Type of media</h3>
        <app-checkbox
          v-for="(item, index) in sortFilterOptions.filterMediaArray"
          :key="index"
          :id="index"
          :labelText="item.labelText"
          :styleClass="'marketplace'"
          class="filtering-option-checkbox"
          :checked="isChecked(item.type, item.value)"
          @click="filterFunction({ type: item.type, value: item.value })"
        />
      </template>
      <template v-if="filterOptions.includes('price')">
        <h3 class="filtering-options-heading">Price range</h3>
        <app-checkbox
          v-for="(item, index) in sortFilterOptions.filterPriceRangeArray"
          :key="index"
          :id="index"
          :labelText="item.labelText"
          :styleClass="'marketplace'"
          class="filtering-option-checkbox"
          :checked="isChecked(item.type, item.priceRange)"
          @click="
            filterFunction({ type: item.type, priceRange: item.priceRange })
          "
        />
      </template>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";
import AppInput from "@/elements/AppInput";
import AppCheckbox from "@/elements/AppCheckbox";
import sortFilterOptions from "@/json/marketplaceSortFilterOptions";

export default {
  components: {
    AppInput,
    AppCheckbox,
  },
  props: {
    showTraitrsFilter: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    filterFunction: {
      type: Function,
      default: () => {},
    },
    filterOptions: {
      type: Array,
      default: () => ["network", "media", "price"],
    },
    isChecked: {
      type: Function,
      default: () => {},
    },
  },
  setup() {
    const showFilters = ref(false);
    const filtersFlag = ref(false);
    const nameFilterValues = ref("");
    const store = useStore();
    const traitsOptions = computed(() => {
      return store.getters["singleCollection/getByKey"]("traitFilterOptions");
    });
    const toggle = () => {
      showFilters.value = !showFilters.value;
    };

    onMounted(async () => {
      nameFilterValues.value = await store.dispatch(
        "collections/fetchCollectionsNames"
      );
    });

    const setFiltersFlag = (flag) => {
      filtersFlag.value = flag;
    };
    return {
      toggle,
      showFilters,
      traitsOptions,
      setFiltersFlag,
      nameFilterValues,
      sortFilterOptions,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/Sort.scss" />
<style scoped>
.filtering-options {
  max-height: 610px;
  overflow-y: auto;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 5px;
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
