<template>
  <div class="app-input" :class="[componentClass, errorMessage ? 'error' : '']">
    <div class="royalties-icon-wrap">
      <label :for="id" :class="labelClass">{{ labelText }}</label>
      <!-- <div v-if="name == 'Royalties'" class="royalties-info">
        <img
          class="royalties-icon royalties-img"
          src="@/Common/Icons/InfoRoyaltiesIcon.png"
        />
        <div class="royalties-hover">
          Collect a fee when a user re-sells an item<br />
          you originally created.
        </div>
      </div> -->
      <div v-if="infoText" class="royalties-info">
        <img
          class="royalties-icon royalties-img"
          src="@/Common/Icons/InfoRoyaltiesIcon.png"
        />
        <div class="royalties-hover" v-html="infoText"></div>
      </div>
    </div>
    <div :class="inputIcons">
      <i
        v-if="inputIconLeftClass"
        :class="inputIconLeftClass"
        :style="inputIconLeftStyle"
      ></i>
      <i
        v-if="inputIconRightClass"
        :class="inputIconRightClass"
        :style="inputIconRightStyle"
      ></i>
      <div class="input-wrapper">
        <p class="floating-text" v-if="floatingText">
          {{ floatingText }}
        </p>
        <p class="floating-text-left" v-if="floatingTextLeft">
          {{ floatingTextLeft }}
        </p>
        <input
          :id="id"
          :name="name"
          v-bind="$attrs"
          :style="inputStyle"
          v-model="inputValue"
          :disabled="disabled"
          :maxlength="maxLength"
          :placeholder="placeholder"
          @input="$emit('update:modelValue', $event.target.value)"
          @focusout="$emit('update:modelValue', $event.target.value)"
          :class="[
            inputClass,
            errorMessage ? 'error-message-border' : '',
            floatingTextLeft ? 'float-text-left-input' : '',
          ]"
        />
      </div>
      <!-- <span v-if="bottomLabel" class="description-num-chars"
        >{{ bottomLabel }}
      </span> -->
      <div class="percents-box" v-if="suggestions">
        <p>20% maximum. Suggestions:</p>

        <div v-for="(percent, index) in percents" :key="index" class="percents">
          <div @click="$emit('update:modelValue', parseFloat(percent.text))">
            {{ percent.text }},
          </div>
        </div>
      </div>
    </div>
    <div
      v-if="bottomLabel"
      style="display: flex; justify-content: space-between"
    >
      <div
        v-show="!errorMessage || meta.valid"
        class="description-num-chars"
        :class="disabled ? disabledClass : ''"
      >
        {{ bottomLabel }}
      </div>
      <p
        v-if="bottomLabel"
        :class="[messageClass, errorMessage ? 'validation-error-message' : '']"
        v-show="errorMessage || !meta.valid"
      >
        {{ errorMessage }}
      </p>
      <span v-if="maxLength" class="description-num-chars">
        {{ inputValue ? inputValue.length : 0 }}/{{ maxLength }}
      </span>
    </div>
    <p
      v-if="!bottomLabel"
      :class="[messageClass, errorMessage ? 'validation-error-message' : '']"
      v-show="errorMessage || !meta.valid"
    >
      {{ errorMessage }}
    </p>
  </div>
</template>

<script>
import { useStore } from "vuex";
import { onMounted, onUnmounted, toRefs, watch } from "vue";
import { useField } from "vee-validate";
import useInputErrors from "../composables/useInputErrors";
export default {
  name: "AppInput",
  props: {
    id: {
      type: String,
    },
    successMessage: {
      type: String,
      default: "",
    },
    name: {
      type: String,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    labelText: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    labelClass: {
      type: String,
      default: "",
    },
    inputClass: {
      type: String,
      default: "",
    },
    componentClass: {
      type: String,
      default: "",
    },
    maxLength: {
      type: Number,
      default: null,
    },
    inputStyle: {
      type: Object,
      default: () => {},
    },
    inputIconLeftClass: {
      type: String,
      default: "",
    },
    inputIconLeftStyle: {
      type: Object,
      default: () => {},
    },
    inputIconRightClass: {
      type: Object,
      default: () => {},
    },
    inputIconRightStyle: {
      type: Object,
      default: () => {},
    },
    inputIcons: {
      type: String,
      default: "",
    },
    messageClass: {
      type: String,
      default: "",
    },
    suggestions: {
      type: Boolean,
      default: false,
    },
    bottomLabel: {
      type: String,
      default: "",
    },
    infoText: {
      type: String,
      default: "",
    },
    percents: {
      type: Array,
      default: () => [],
    },
    validationFunction: {
      type: Function,
      default: () => {},
    },
    floatingText: {
      type: String,
      default: "",
    },
    floatingTextLeft: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { watchErrorMessage, clearErrorMessages } = useInputErrors(
      useStore()
    );
    let listNftWatcher, mintNftWatcher;

    const {
      value: inputValue,
      errorMessage,
      meta,
    } = useField(props.name, props.validationFunction() || undefined, {
      initialValue: props.modelValue,
    });

    let { modelValue } = toRefs(props);
    watch(
      () => modelValue,
      (modelValue) => {
        inputValue.value = modelValue.value;
      },
      { deep: true }
    );

    onMounted(() => {
      listNftWatcher = watchErrorMessage(errorMessage, "listNft");
      mintNftWatcher = watchErrorMessage(errorMessage, "minting");
    });
    onUnmounted(() => {
      listNftWatcher();
      mintNftWatcher();
      clearErrorMessages("listNft");
      clearErrorMessages("minting");
    });

    return {
      meta,
      inputValue,
      errorMessage,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/appInput.scss" />
