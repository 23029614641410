import * as Yup from "yup";

export const updateCollectionSchema = Yup.object().shape({
  name: Yup.string()
    .required("Name is a requred field")
    .max(50, "Name value must be less then 50"),
  originalItemsNumber: Yup.number()
    .nullable()
    .typeError("Number of items must be a number"),
  description: Yup.string()
    .max(1000, "Description text must be less then 1000 characters")
    .nullable(),
  creationDate: Yup.date().typeError("Date created is a required field"),
});
