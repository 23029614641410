<template>
  <div class="profile-panel-filter-search">
    <search
      :searchFunction="searchFunction"
      :isForMarketplace="isForMarketplace"
      :clearSearchFunction="clearSearchFunction"
      :disabled="currentPanel === 'nfts' && !theosMinted"
    />

    <Filter
      v-if="currentPanel !== 'external' && !isForMarketplace"
      :filterFunction="filterFunction"
      :filterOptions="filterOptions"
      :isChecked="isChecked"
    />
    <sort :sortOptions="sortOptions" :sortFunction="sortFunction" />
    <Filter
      v-if="showTraitrsFilter"
      :showTraitrsFilter="showTraitrsFilter"
      :filterFunction="filterFunction"
      :filterOptions="filterOptions"
      :isChecked="isChecked"
    />
  </div>
</template>

<script>
import { inject } from "vue";
import { useStore } from "vuex";

import Sort from "./Sort.vue";
import Search from "./Search.vue";
import Filter from "./Filter.vue";
import useCollections from "@/composables/useCollections";

export default {
  props: {
    currentPanel: {
      type: String,
      default: "",
    },
    isForMarketplace: {
      type: Boolean,
      default: false,
    },
    showTraitrsFilter: {
      type: Boolean,
      default: false,
    },
    searchFunction: {
      type: Function,
      default: () => {},
    },
    sortFunction: {
      type: Function,
      default: () => {},
    },
    sortOptions: {
      type: Array,
      default: () => [],
    },
    filterFunction: {
      type: Function,
      default: () => {},
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
    isChecked: {
      type: Function,
      default: () => {},
    },
    clearSearchFunction: {
      type: Function,
      default: () => {},
    },
  },
  components: {
    Filter,
    Sort,
    Search,
  },
  setup() {
    const isPublic = inject("isPublic");
    const { theosMinted } = useCollections(useStore());

    return {
      isPublic,
      theosMinted,
    };
  },
};
</script>

<style lang="scss" scoped src="@/Common/Styles/profilePanels.scss" />
